<mat-nav-list class="nav-list-container">
  <mat-list-item
    *ngFor="let item of items"
    [ngClass]="{ selected: item.isSelected }"
    (click)="itemClicked(item)"
  >
    <app-icon
      class="img"
      [iconName]="item.iconName"
      width="25px"
      height="25px"
    ></app-icon>
    <span>{{ item.name }}</span>
  </mat-list-item>
</mat-nav-list>
