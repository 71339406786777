import { AuthActions, AuthActionTypes } from './actions';
import { initialAuthState, IAuthState } from './state';

export function authReducer(state = initialAuthState, action: AuthActions): IAuthState {
  switch (action.type) {
    case AuthActionTypes.AuthenticationSuccess: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case AuthActionTypes.Logout: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }

    case AuthActionTypes.LoadUserDataSuccess: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case AuthActionTypes.LoadUserPhotoSuccess: {
      return {
        ...state,
        userPhotoUrl: action.payload.photoUrl as string,
      };
    }

    case AuthActionTypes.LoadUserPhotoFailure: {
      return {
        ...state,
        userPhotoUrl: action.payload.defaultPhotoUrl as string
      };
    }

    default:
      return state;
  }
}
