import { createAction, props } from "@ngrx/store";

export enum LayoutActionTypes {
  SetSideNavFocus = "[Layout] Set focus on selected SideNav item",
}

export const setSidebarItemName = createAction(
  LayoutActionTypes.SetSideNavFocus,
  props<{ sidebarItemName: string }>()
);
