import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() color: ThemePalette = "primary";
  @Input() isDisabled: boolean = false;
  @Input() isFilledIn: boolean = true;
  @Input() content: string;

  @Output() onClick = new EventEmitter();

  onClickHandler() {
    if (!this.isDisabled) {
      this.onClick.emit();
    }
  }
}
