import { Injectable } from "@angular/core";
import { ofType, Actions, createEffect } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { navigateToContracts } from "./router.actions";
import { Router } from "@angular/router";
import { EMPLOYEES_PATH } from "src/app/contracts/employees-contracts/employees-contracts.component";

@Injectable()
export class RouterEffects {
  constructor(private _actions$: Actions, private _router: Router) {}

  navigateToContractsEffect$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(navigateToContracts),
        switchMap((payload) => {
          const route = `/${payload.item.navigationPath ?? EMPLOYEES_PATH}`;
          return this._router.navigateByUrl(route);
        })
      );
    },
    { dispatch: false }
  );
}
