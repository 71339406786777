import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "src/core/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService) {}

  acceptedGroups = ["Workers", "Team Engine"];

  canActivate(): boolean {
    const user = this._authService.getUser();
    return user && this.isUserAuthorized(user.groups);
  }

  private isUserAuthorized(groups: string[]): boolean {
    if (Array.isArray(groups) && groups.length < 1) return false;

    const result = groups.filter((group) => this.acceptedGroups.includes(group));
    
    return result.length > 0;
  }
}
