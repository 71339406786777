<app-header
  [title]="cardsNames.rollingContracts"
  class="header"
  *ngIf="filtersOptions$ | async as options"
>
  <div dropdowns class="filters-container">
    <app-dropdown
      placeholder="Visibility"
      [options]="options[optionNames.visibility]"
      [control]="form.get(optionNames.visibility)"
      (onChange)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Year"
      [options]="options[optionNames.year]"
      [control]="form.get(optionNames.year)"
      (onChange)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Team"
      [options]="options[optionNames.team]"
      [control]="form.get(optionNames.team)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Project"
      [options]="options[optionNames.projectName]"
      [control]="form.get(optionNames.projectName)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Probability"
      [options]="options[optionNames.confidence]"
      [control]="form.get(optionNames.confidence)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Rolling"
      [options]="options[optionNames.rolling]"
      [control]="form.get(optionNames.rolling)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
  </div>
</app-header>
<div class="content-box">
  <app-grid
    [gridDefinition]="grid$ | async"
    [data]="data$ | async"
    [inputPattern]="inputPattern"
    [optionSet]="optionSets$ | async"
    [isZeroChangedToNull]="true"
    numberFormat="0.0"
    (onSaveData)="saveUpdates($event)"
    (onFocus)="setWeekId($event)"
    [(editedDataItem)]="editedDataItem"
    [(selectedDataItem)]="selectedDataItem"
    [(lastClickedFieldName)]="lastClickedFieldName"
    [hiddenColumns]="hiddenColumns"
    [optionSetTextColor]="'black'"
    [useDefaultColorInOptionList]="true"
    [selectHasEmptyRow]="false"
    [filterSelectOption]="filterSelectOption"
    [getNumericInputBackgroundColor]="getNumericInputBackgroundColor"
    [externalDataChangeEvent]="fillMultipleEvent"
    [aggregateResult]="aggregateResult"
    [autocompleteOptions]="autocompleteOptions$ | async"
  >
    <app-button
      actions
      content="Create"
      (mousedown)="openCreateRollingContractDialog()"
      [isDisabled]="false"
    ></app-button>
    <app-button
      actions
      content="Fill until end of month"
      (mousedown)="fillEndOfTheMonth()"
      [isDisabled]="!lastClickedFieldName"
    ></app-button>
    <app-button
      actions
      content="Fill multiple"
      (mousedown)="openMultiFillDialog()"
      [isDisabled]="!lastClickedFieldName"
    ></app-button>
  </app-grid>
</div>
