<div class="switch-container">
  <p class="title">{{ leftOption }}</p>
  <div class="switch-box">
    <label class="switch">
      <input type="checkbox" [formControl]="control" />
      <span class="slider"></span>
    </label>
  </div>
  <p class="title">{{ rightOption }}</p>
</div>
