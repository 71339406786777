import { createSelector, createFeatureSelector } from "@ngrx/store";
import { LayoutState } from "./layout.state";
import { getNavItemFromUrl } from "src/core/stores/router-store/router.selectors";

export const layoutSelector = createFeatureSelector<LayoutState>("layout");

export const getSelectedSidebarItemName = createSelector(
  layoutSelector,
  (state: LayoutState) => state.selectedSidebarItemName
);

export const getNavListItemFromUrl = createSelector(
  layoutSelector,
  getNavItemFromUrl,
  (state: LayoutState, navItemName: string) => {
    return navItemName && navItemName !== "/" ? navItemName : "/employees";
  }
);
