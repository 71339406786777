import { createReducer, on } from "@ngrx/store";
import {
  createRollingContractFailure,
  createRollingContractSuccess,
  loadConfidenceFailure,
  loadConfidenceSuccess,
  loadContractedEmployeesFailure,
  loadContractedEmployeesSuccess,
  loadContractedProjectsFailure,
  loadContractedProjectsSuccess,
  loadEmployeesFailure,
  loadEmployeesSuccess,
  loadProjectsFailure,
  loadProjectsSuccess,
  loadRollingContractsFailure,
  loadRollingContractsSuccess,
  loadTeamsFailure,
  loadTeamsSuccess,
  updateContractedEmployeeFailure,
  updateContractedEmployeeSuccess,
  updateContractedProjectFailure,
  updateContractedProjectSuccess,
  updateRollingContractFailure,
  updateRollingContractSuccess,
} from "./contracts.actions";
import { initialContractsState } from "./contracts.state";

export const contractsReducer = createReducer(
  initialContractsState,
  on(loadContractedEmployeesSuccess, (state, { contractedEmployees }) => ({
    ...state,
    contractedEmployees,
    error: null,
  })),
  on(loadContractedEmployeesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadContractedProjectsSuccess, (state, { contractedProjects }) => ({
    ...state,
    contractedProjects,
    error: null,
  })),
  on(loadContractedProjectsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadTeamsSuccess, (state, { teams }) => ({
    ...state,
    teams,
    error: null,
  })),
  on(loadTeamsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
    error: null,
  })),
  on(loadEmployeesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
    error: null,
  })),
  on(loadProjectsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(updateContractedEmployeeSuccess, (state, { updatedEmployee }) => {
    return {
      ...state,
      contractedEmployees: {
        ...state.contractedEmployees,
        [updatedEmployee.id]: updatedEmployee,
      },
    };
  }),
  on(updateContractedEmployeeFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(updateContractedProjectSuccess, (state, { updatedProject }) => {
    return {
      ...state,
      contractedProjects: {
        ...state.contractedProjects,
        [updatedProject.id]: updatedProject,
      },
    };
  }),
  on(updateContractedProjectFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadRollingContractsSuccess, (state, { rollingContracts }) => ({
    ...state,
    rollingContracts,
  })),
  on(loadRollingContractsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadConfidenceSuccess, (state, { confidences }) => ({
    ...state,
    confidences,
  })),
  on(loadConfidenceFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(createRollingContractSuccess, (state, { rollingContract }) => ({
    ...state,
    rollingContracts: {
      ...state.rollingContracts,
      [rollingContract.id]: rollingContract,
    },
  })),
  on(createRollingContractFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(updateRollingContractSuccess, (state, { updatedRollingContract }) => {
    const tempRollingContract = {
      ...state.rollingContracts,
      [updatedRollingContract.id]: updatedRollingContract,
    };

    return {
      ...state,
      rollingContracts: tempRollingContract,
    };
  }),
  on(updateRollingContractFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
