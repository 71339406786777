import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { AggregateResult, State } from "@progress/kendo-data-query";
import { skipWhile, takeUntil } from "rxjs/operators";
import { ColorsBase } from "src/app/app.model";
import { AppState } from "src/core/app.state";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import { BusyIndicatorService } from "src/core/interceptors/busy-indicator.service";
import { SelectOption } from "src/core/models/select-option";
import { DateTimeService } from "src/core/services/date-time.service";
import { loadCalendars } from "src/core/stores/calendar-store/calendar.actions";
import { getCurrentWeekNumber } from "src/core/stores/calendar-store/calendar.selectors";
import { ArrowKeys } from "src/shared/shared.model";
import {
  OnChangeSelectEmitObj,
  OnFocusSelectEmitObj,
  OnKeyDownEmitObj,
} from "../select/select.model";
import { Column, GridDefinition, optionSetKeys } from "./grid-definition";
import { weekColumnRegex } from "./grid.model";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent
  extends SubscribedContainer
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild("grid", { static: false }) gridElement!: any;
  @Input() gridDefinition: GridDefinition;
  @Input() data: any[];
  @Input() optionSet: { [key: string]: SelectOption[] };
  @Input() state: State = {
    skip: 0,
    take: 20,
  };
  @Input() colors: ColorsBase;
  @Input() optionSetTextColor: string = "white";
  @Input() editedDataItem: any = null;
  @Input() selectedDataItem: any = null;
  @Input() lastClickedFieldName: string | null = null;
  @Input() isZeroChangedToNull: boolean = false;
  @Input() selectHasEmptyRow: boolean = true;
  @Input() useDefaultColorInOptionList: boolean = false;
  @Input() numberFormat: string = "0.2";
  @Input() culture: string = "en-US";
  @Input() inputPattern: string;
  @Input() backgroundColors: ColorsBase;
  @Input() hiddenColumns: Record<string, boolean> = {};
  @Input() getNumericInputBackgroundColor?: (dataRow: any[]) => string;
  @Input() aggregateResult?: AggregateResult = undefined;
  @Input() autocompleteOptions: {
    projectName?: string[];
    employeeName?: string[];
    teamSymbol?: string[];
  } = {};

  @Input() filterSelectOption?: (
    value: string,
    selectOptions?: SelectOption[]
  ) => SelectOption[] | undefined;
  @Input() externalDataChangeEvent?: EventEmitter<void>;

  @Output() onSaveData = new EventEmitter<any>();

  @Output() editedDataItemChange = new EventEmitter<any>();
  @Output() selectedDataItemChange = new EventEmitter<any>();
  @Output() lastClickedFieldNameChange = new EventEmitter<string | null>();
  @Output() onFocus = new EventEmitter<string>();

  saveEvent = new EventEmitter();
  isAnyFieldFocused = false;
  currentWeekNumber = null;
  currentYear: string;
  isDirty = false;
  showIndicator = false;
  calculatedColumnWidths: Record<string, string> = {};
  dirtyFields: string[] = [];
  filteredSelectOptionKeys: string[] = [optionSetKeys.confidence];

  constructor(
    private _formBuilder: FormBuilder,
    private _busyIndicatorService: BusyIndicatorService,
    private _store: Store<AppState>,
    _dateTimeService: DateTimeService,

  ) {
    super();
    this._busyIndicatorService.operationsInProgress$.subscribe((operations) => {
      setTimeout(() => (this.showIndicator = operations > 0));
    });
    this.currentYear = _dateTimeService.getCurrentYearAsString();
  }

  ngOnInit(): void {
    this._store.dispatch(loadCalendars());
    
    this._store
      .select(getCurrentWeekNumber)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
    .subscribe((currentWeekNumber) => (this.currentWeekNumber = currentWeekNumber.toString().padStart(2, "0")));

    this.externalDataChangeEvent?.subscribe((_) => this.setDirtyFields());
  }

  ngAfterViewInit(): void {
    document.addEventListener("blur", this.checkIfAnyElementFocused, true);
}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isScrollNeeded(changes)) {
      this.scrollToColumn();
    }
    this.calculateColumnWidth();
  }

  scrollToColumn() {
      setTimeout(() => {
          let columnIdx = this.gridDefinition.columns.findIndex((col) =>
          col.field.includes(`w${this.currentWeekNumber}`)
          );
          this.gridElement.scrollTo({ column: columnIdx - 4 });
        }, 0);
  }

  calculateColumnWidth() {
    let columnsToCalculate = this.gridDefinition.columns.filter(
      (col) => col.calculateWidthBasedOnValue
    );

    if (
      columnsToCalculate.length === 0 ||
      !this.data ||
      this.data.length === 0
    ) {
      return;
    }

    columnsToCalculate.forEach((col) => {
      let data = this.data.map((item) => item[col.field]);

      if (data.length > 0) {
        const longestValue = Math.max(
          ...data.map((item) => item.toString().length)
        );

        let multiplier: number = this.getMultiplier(data[0]);

        this.calculatedColumnWidths[col.field] = `${
          longestValue * multiplier
        }px`;
      }
    });
  }

  checkIfAnyElementFocused = (event: Event): void => {
    const targetElement = event.target as HTMLElement;
    const table = document.querySelector("table");
    const isSelectFocused =
      table?.contains(targetElement) &&
      (targetElement.tagName === "SELECT" || targetElement.tagName === "INPUT");

    this.isAnyFieldFocused = isSelectFocused;
  };

  createFormGroup(args: CellClickEvent): FormGroup {
    let { dataItem, column } = args;
    const formControls = {};

    if (column.isEditable) {
      formControls[column.field] = new FormControl(dataItem[column.field]);
    }

    return this._formBuilder.group(formControls);
  }

  cellClickHandler(args: CellClickEvent): void {
    if (!args.isEdited) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args)
      );
    }
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;

    if (this.isControlsEdited(formGroup.controls)) {
      let objToSend = { ...dataItem };

      Object.keys(formGroup.value).forEach((prop) => {
        objToSend[prop] = formGroup.value[prop];
      });

      this.onSaveData.emit(objToSend);
    }
  }

  toggleActive(dataItem: any) {
    dataItem = Object.assign(
      {},
      { ...dataItem, isVisible: !dataItem.isVisible }
    );
    this.onSaveData.emit(dataItem);
  }

  getValue(dataItem: any, field: string) {
    let parts = field.split(".");
    let value = dataItem;
    for (let part of parts) {
      value = value[part];
      if (value == null) return null;
    }

    return value;
  }

  isControlsEdited(controls: any): boolean {
    let jsonKeys = Object.keys(controls);
    let isEdited = false;

    jsonKeys.forEach((key: string) => {
      let control: FormControl = controls[key];
      if (control.touched && control.dirty && !isEdited) {
        isEdited = true;
        return;
      }
    });

    return isEdited;
  }

  onSelectChange(object: OnChangeSelectEmitObj, key: string) {
    let { value } = object;

    if (
      this.optionSet[key].filter((x) => x.value === value).length === 1 ||
      value === null
    ) {
      this.changeFieldValue(object);
    }
  }

  onFieldFocus(emitedObj: OnFocusSelectEmitObj) {
    let { dataItem, fieldName } = emitedObj;

    this.lastClickedFieldNameChange.emit(fieldName);
    this.isAnyFieldFocused = true;
    this.selectedDataItemChange.emit(dataItem);

    let weekId = this.gridDefinition.columns.find(
      (x) => x.field === fieldName
    ).weekId;

    weekId && this.onFocus.emit(weekId);

    if (
      this.isDirty &&
      this.editedDataItem &&
      this.editedDataItem.id !== dataItem.id
    ) {
      this.onSaveHandler();
    }
  }

  onFieldFocusOut() {
    this.isAnyFieldFocused = false;
    setTimeout(() => {
      if (!this.isAnyFieldFocused) {
        this.selectedDataItemChange.emit(null);
        this.lastClickedFieldNameChange.emit(null);

        if (this.editedDataItem) {
          this.onSaveHandler();
        }
      }
    });
  }

  onArrowDown(obj: OnKeyDownEmitObj) {
    if (!this.showIndicator) {
      const numbers = obj.id.match(/\d+/g);
      let weekCol = parseInt(numbers[0]);
      let row = parseInt(numbers[1]);

      if (obj.key === ArrowKeys.Up) row--;
      else if (obj.key === ArrowKeys.Right) weekCol++;
      else if (obj.key === ArrowKeys.Down) row++;
      else if (obj.key === ArrowKeys.Left) weekCol--;

      document
        .getElementById(
          `${obj.id[0]}${weekCol.toString().padStart(2, "0")}${obj.id[3]}${row}`
        )
        ?.focus();
    }
  }

  private changeFieldValue(obj: OnChangeSelectEmitObj) {
    let { id, fieldName, dataItem } = obj;

    this.setDirtyFields(id);
    this.lastClickedFieldNameChange.emit(fieldName);
    this.editedDataItemChange.emit(dataItem);
  }

  private onSaveHandler() {
    this.onSaveData.emit(this.editedDataItem);
    this.editedDataItemChange.emit(null);
    this.setDirtyFields();
    this.saveEvent.emit();
  }

  private isScrollNeeded(changes: SimpleChanges) {
    return (
      ((changes.data &&
        changes.data.previousValue?.length === 0 &&
        changes.data.currentValue?.length > 0) ||
        (changes.gridDefinition &&
          !this.isSameGridDefinition(
            changes.gridDefinition.previousValue,
            changes.gridDefinition.currentValue
          ))) &&
      this.gridDefinition?.columns.find((x) => weekColumnRegex.test(x.title))
    );
  }

  private isSameGridDefinition(prev: GridDefinition, current: GridDefinition) {
    if (prev?.columns?.length !== current?.columns?.length) {
      return false;
    }

    return prev.columns?.every(
      (col: Column, idx: number) => col.field === current.columns[idx]?.field
    );
  }

  private setDirtyFields(fieldId?: string) {
    if (!fieldId) {
      this.dirtyFields = [];
      this.isDirty = false;
    } else if (!this.dirtyFields.includes(fieldId)) {
      this.dirtyFields.push(fieldId);
      this.isDirty = true;
    }
  }

  private getMultiplier(item: any): number {
    switch (typeof item) {
      case "string":
        return 7;
      case "number":
        return 8.8;
    }
  }
}
