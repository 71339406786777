import { SafeUrl } from '@angular/platform-browser';
import { Action } from '@ngrx/store';
import { AzureUser } from 'src/core/models/azure-user.model';

export enum AuthActionTypes {
  AuthenticationSuccess = '[Auth] Authentication Success',
  Logout = '[Auth] Logout',
  LoadUserPhoto = '[Auth] Load User Photo',
  LoadUserPhotoSuccess = '[Auth] Load User Photo Success',
  LoadUserPhotoFailure = '[Auth] Load User Photo Failure',
  LoadUserData = '[Auth] Load User Data',
  LoadUserDataSuccess = '[Auth] Load User Data Success',
}

export class AuthenticationSuccess implements Action {
  public readonly type = AuthActionTypes.AuthenticationSuccess;
  constructor() { }
}

export class Logout implements Action {
  public readonly type = AuthActionTypes.Logout;
}
export class LoadUserPhoto implements Action {
  public readonly type = AuthActionTypes.LoadUserPhoto;
}

export class LoadUserData implements Action {
  public readonly type = AuthActionTypes.LoadUserData;
}

export class LoadUserDataSuccess implements Action {
  public readonly type = AuthActionTypes.LoadUserDataSuccess;
  public constructor(public payload: { user: AzureUser }) { }
}
export class LoadUserPhotoSuccess implements Action {
  public readonly type = AuthActionTypes.LoadUserPhotoSuccess;
  constructor(public payload: { photoUrl: SafeUrl }) { }
}

export class LoadUserPhotoFailure implements Action {
  public readonly type = AuthActionTypes.LoadUserPhotoFailure;
  constructor(public payload: { defaultPhotoUrl: SafeUrl }) { }
}
export type AuthActions = AuthenticationSuccess
  | LoadUserPhoto
  | LoadUserPhotoSuccess
  | LoadUserData
  | LoadUserDataSuccess
  | LoadUserPhotoFailure
  | Logout;
