import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'node_modules/lodash/index';

@Pipe({ name: 'deepCopy' })
export class DeepCopyPipe implements PipeTransform {
  transform(object: object): object {
    if (object) {
      return _.cloneDeep(object);
    }

    return null;
  }
}
