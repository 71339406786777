import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-switch-box",
  templateUrl: "./switch-box.component.html",
  styleUrls: ["./switch-box.component.scss"],
})
export class SwitchBoxComponent implements OnInit {
  @Input() leftOption: string;
  @Input() rightOption: string;
  @Input() control?: FormControl | undefined = undefined;

  constructor() {}

  ngOnInit(): void {}
}
