import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { skipWhile, takeUntil } from "rxjs/operators";
import { AppState } from "src/core/app.state";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import { RollingContractDto, WeekDto } from "src/core/services/api/api-clients";
import { getCalendars } from "src/core/stores/calendar-store/calendar.selectors";
import {
  MultiFillDialogConfig,
  MultiFillDialogFieldNames,
} from "./multi-fill-dialog.model";
import { validateRangeAndInteger } from "./multi-fill-dialog.validators";

@Component({
  selector: "app-multi-fill-dialog",
  templateUrl: "./multi-fill-dialog.component.html",
  styleUrls: ["./multi-fill-dialog.component.scss"],
})
export class MultiFillDialogComponent
  extends SubscribedContainer
  implements OnInit
{
  formFieldNames = MultiFillDialogFieldNames;
  formGroup: FormGroup;

  weeks: WeekDto[];
  disableFillButton = true;

  constructor(
    private _dialogRef: MatDialogRef<MultiFillDialogConfig>,
    private _store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) private data: MultiFillDialogConfig
  ) {
    super();
  }

  ngOnInit(): void {
    this._store
      .select(getCalendars)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((weeks) => (this.weeks = weeks));

    this.setFormGroup();
    this.setValidators();
    this.subscribeToFormGroupStatusChanges();
  }

  confirm(): void {
    if (
      this.data &&
      this.data.onFill &&
      this.data.dataItem &&
      this.data.choosedWeekNumber
    ) {
      let dataItem = this.fillMultiHours();
      this.data.onFill(dataItem);
    }

    this._dialogRef.close();
  }

  private setFormGroup(): void {
    this.formGroup = new FormGroup({
      [this.formFieldNames.perWeek]: new FormControl(false),
      [this.formFieldNames.initialWeek]: new FormControl(
        this.data.choosedWeekNumber ?? "",
        [Validators.min(1), Validators.max(this.weeks.length)]
      ),
      [this.formFieldNames.finalWeek]: new FormControl(""),
      [this.formFieldNames.hours]: new FormControl("", [Validators.min(0)]),
    });
  }

  private setValidators(): void {
    this.formGroup.addValidators(
      validateRangeAndInteger(
        this.formFieldNames.initialWeek,
        this.formFieldNames.finalWeek,
        this.weeks.length
      )
    );
  }

  private subscribeToFormGroupStatusChanges() {
    this.formGroup.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        if (this.formGroup.touched && this.formGroup.status === "VALID") {
          this.disableFillButton = false;
        } else {
          this.disableFillButton = true;
        }
      });
  }

  private fillMultiHours(): RollingContractDto {
    const formValues = this.formGroup.value;
    let min = +formValues[this.formFieldNames.initialWeek];
    let max =
      formValues[this.formFieldNames.finalWeek] != ""
        ? +formValues[this.formFieldNames.finalWeek]
        : this.weeks.length;
    let filledData = { ...this.data.dataItem } as RollingContractDto;
    let setHoursPerWeek: boolean = formValues[this.formFieldNames.perWeek];

    let valuePerWeek = this.roundToNearestQuarter(
      formValues[this.formFieldNames.hours] /
        (setHoursPerWeek ? 1 : max - min + 1)
    );

    for (; min <= max; min++) {
      filledData[`w${min.toString().padStart(2, "0")}`] = valuePerWeek;
    }

    return filledData;
  }

  private roundToNearestQuarter(value: number): number {
    return Math.ceil(value * 4) / 4;
  }
}
