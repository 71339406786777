import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { calendarStoreName } from "./calendar.state";
import { calendarReducer } from "./calendar.reducer";
import { EffectsModule } from "@ngrx/effects";
import { CalendarEffects } from "./calendar.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(calendarStoreName, calendarReducer),
    EffectsModule.forFeature([CalendarEffects]),
  ],
})
export class CalendarStoreModule {}
