import { RollingContractDto } from "src/core/services/api/api-clients";

export class MultiFillDialogConfig {
  onFill: (rollingContract: RollingContractDto) => void;
  choosedWeekNumber: string;
  dataItem: RollingContractDto;
}

export enum MultiFillDialogFieldNames {
  perWeek = "perWeek",
  initialWeek = "initialWeek",
  finalWeek = "finalWeek",
  hours = "hours",
}
