import { Component, Input } from "@angular/core";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
  private _iconPath: string;

  @Input()
  public set iconName(value: string) {
    const basePath = "/assets/icons";

    if (value) {
      const fileName = value.endsWith(".svg") ? value : `${value}.svg`;
      this._iconPath = `${basePath}/${fileName}`;
    }
  }

  public get iconPath(): string {
    return this._iconPath;
  }

  @Input() width: string = "auto";
  @Input() height: string = "auto";
}
