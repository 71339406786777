import { LayoutState, initialLayoutState } from "./layout.state";
import { setSidebarItemName } from "./layout.actions";
import { createReducer, on } from "@ngrx/store";

export const layoutReducer = createReducer(
  initialLayoutState,
  on(setSidebarItemName, (state: LayoutState, { sidebarItemName }) => {
    return {
      ...state,
      selectedSidebarItemName: sidebarItemName,
    };
  })
);
