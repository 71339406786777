import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import {
  EMPLOYEES_PATH,
  EmployeesContractsComponent,
} from "./contracts/employees-contracts/employees-contracts.component";
import {
  PROJECTS_PATH,
  ProjectsContractsComponent,
} from "./contracts/projects-contracts/projects-contracts.component";
import {
  ROLLING_PATH,
  RollingContractsComponent,
} from "./contracts/rolling-contracts/rolling-contracts.component";
import { AuthGuard } from "src/core/guards/auth/auth.guard";

const routes: Routes = [
  {
    path: EMPLOYEES_PATH,
    component: EmployeesContractsComponent,
    canActivate: [AuthGuard, MsalGuard],
  },
  {
    path: PROJECTS_PATH,
    component: ProjectsContractsComponent,
    canActivate: [AuthGuard, MsalGuard],
  },
  {
    path: ROLLING_PATH,
    component: RollingContractsComponent,
    canActivate: [AuthGuard, MsalGuard],
  },

  {
    path: "",
    component: EmployeesContractsComponent,
    canActivate: [AuthGuard, MsalGuard],
  },

  {
    path: "**",
    component: EmployeesContractsComponent,
    canActivate: [AuthGuard, MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
