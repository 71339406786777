<mat-form-field class="autocomplete-field">
  <mat-label>{{ label }}</mat-label>
  <input
    [type]="type"
    [placeholder]="placeholder"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    [required]="required"
    [step]="type === 'number' ? 1 : null"
    [pattern]="type === 'number' ? '[+-]?[0-9]{0,}' : null"
  />
  <button
    *ngIf="control.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="control.setValue('')"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.hasError('required')">
    Field is <strong>required</strong>
  </mat-error>
  <mat-error
    *ngIf="!control.hasError('required') && control.hasError('invalidValue')"
  >
    The specified value <strong>does not exist</strong>
  </mat-error>
  <mat-error
    *ngIf="
      type === 'number' && (control.hasError('min') || control.hasError('max'))
    "
  >
    Value must be
    <strong>{{
      control.hasError("min")
        ? "equal to or greater than " + control.errors?.min?.min
        : "less than or equal to " + control.errors?.max?.max
    }}</strong>
  </mat-error>
  <mat-error
    *ngIf="
      type === 'number' &&
      (control.hasError('pattern') || control.hasError('isFloatNumber'))
    "
  >
    The value <strong>must be an integer</strong>
  </mat-error>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.value"
    >
      {{ option.value }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
