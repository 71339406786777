import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
  Injector,
} from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { StoreModule } from "@ngrx/store";
import { AuthService } from "./auth/auth.service";
import { EffectsModule } from "@ngrx/effects";
import { MaterialModule } from "src/shared/material.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
} from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MenuSidenavComponent } from "./layout/menu-sidenav/menu-sidenav.component";
import { BusyIndicatorInterceptor } from "./interceptors/busy-indicator.interceptor";
import { CustomRouterStateSerializer } from "./stores/router-store/router.state";
import { metaReducers } from "./app.state";
import { LayoutStoreModule } from "./stores/layout-store/layout-store.module";
import { RouterStoreModule } from "./stores/router-store/router-store.module";
import { AuthModule } from "./auth/auth.module";
import { API_BASE_URL } from "./services/api/api-clients";
import { SharedModule } from "src/shared/shared.module";
import { ContractsStoreModule } from "./stores/contracts-store/contracts-store.module";
import { CalendarStoreModule } from "./stores/calendar-store/calendar-store.module";

export function init(injector: Injector) {
  return () => injector.get(AuthService).startListening();
}

@NgModule({
  declarations: [
    // layout
    MenuSidenavComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    AuthModule,
    LayoutStoreModule,
    RouterStoreModule,
    ContractsStoreModule,
    CalendarStoreModule,
    StoreModule.forRoot(<any>{}, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SharedModule,
  ],
  exports: [
    CommonModule,
    FormsModule,

    // layout
    MenuSidenavComponent,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.baseUrl,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule has already been loaded. You should only import Core modules in the AppModule only."
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BusyIndicatorInterceptor,
          multi: true,
        },
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStateSerializer,
        },
      ],
    };
  }
}
