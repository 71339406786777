import { Injectable } from "@angular/core";
import {
  weekColumnRegex,
} from "src/shared/components/grid/grid.model";

@Injectable({
  providedIn: "root",
})
export class GridService {
  fillTillEnd(
    editedDataItem: any,
    selectedDataItem: any,
    lastClickedFieldName: string | null
  ) {
    let dataItem =
      editedDataItem !== null
        ? editedDataItem
        : selectedDataItem;
    let value = dataItem[lastClickedFieldName];
    let isEditMode = false;

    Object.keys(dataItem)
      .sort()
      .forEach((key) => {
        if (isEditMode && weekColumnRegex.test(key)) {
          dataItem[key] = value;
        } else if (key === lastClickedFieldName) {
          isEditMode = true;
        }
      });

    selectedDataItem = null;
    lastClickedFieldName = null;
    editedDataItem = null;

    return dataItem;
  }
}
