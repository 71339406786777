<div class="grid-btn-container">
  <ng-content select="[actions]"></ng-content>
</div>
<kendo-grid
  #grid
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  [data]="context.rows"
  *ngIf="{
    columns: gridDefinition.columns,
    rows: data,
    optionSet: optionSet
  } as context"
  class="default-grid-style"
>
  <ng-container *ngFor="let column of context.columns; let colIdx = index">
    <kendo-grid-column
      [field]="column.field"
      [editor]="column.type"
      [headerClass]="
        column.field.includes(currentWeekNumber) && data[0]?.year === currentYear
          ? 'header-active'
          : 'header-basic'
      "
      [class]="
        column.field.includes(currentWeekNumber) && data[0]?.year === currentYear ? 'active-cell' : 'basic-cell'
      "
      [width]="
        column.calculateWidthBasedOnValue
          ? calculatedColumnWidths[column.field] ?? column.width
          : column.width
      "
      [locked]="column.isLocked"
      [hidden]="hiddenColumns[column.field] ?? false"
    >
      <ng-template kendoGridHeaderTemplate>
        <div
          class="header-basic-container"
          [ngClass]="{
            'header-aling-left': column.justifyHeaderTo == 'left',
            'header-aling-right': column.justifyHeaderTo == 'right'
          }"
        >
          <span>{{ column.title | titlecase }}</span>
          <span class="week-start-date">{{
            column.startsAt | date: "dd.MM"
          }}</span>
        </div>
      </ng-template>
      <ng-template
        kendoGridCellTemplate
        let-row
        *ngIf="
          (column.type === 'text' && !column.isEditable) ||
          column.type === 'autonumeration'
        "
      >
        <span
          class="cell-default"
          [ngClass]="{
            'k-grid-ignore-click': !column.isEditable,
            'cell-centered': column.justifyTo == 'center',
            'cell-justified-to-right': column.justifyTo == 'right',
            highlighted: column.isMain && !row.isContracted && data[0].year === currentYear
          }"
        >
          {{
            row.hasOwnProperty(column.field)
              ? row[column.field]
              : context.rows.indexOf(row) + 1
          }}
        </span>
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-row
        let-rowIndex="rowIndex"
        *ngIf="column.type === 'text' && column.isEditable"
      >
        <app-text-input
          [id]="column.field + '-' + rowIndex"
          [value]="row[column.field]"
          [dataItem]="row"
          [fieldName]="column.field"
          [isDirty]="dirtyFields?.includes(column.field + '-' + rowIndex)"
          [options]="
            autocompleteOptions ? autocompleteOptions[column.field] : undefined
          "
          inputPattern=".*"
          (onFocus)="onFieldFocus($event)"
          (onChange)="changeFieldValue($event)"
          (onFocusOut)="onFieldFocusOut()"
          (onArrowDown)="onArrowDown($event)"
        ></app-text-input>
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-row
        *ngIf="column.type === 'boolean'"
      >
        <input
          type="checkbox"
          tabindex="-1"
          [checked]="row.isVisible"
          class="checkbox-default"
          (change)="toggleActive(row)"
        />
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-row
        let-rowIndex="rowIndex"
        *ngIf="column.type === 'numeric'"
      >
        <app-numeric-input
          class="app-numeric-input"
          [id]="column.field + '-' + rowIndex"
          [value]="row[column.field]"
          [dataItem]="row"
          [fieldName]="column.field"
          [numberFormat]="numberFormat"
          [culture]="culture"
          [inputPattern]="inputPattern"
          [isZeroChangedToNull]="isZeroChangedToNull"
          [backgroundColor]="
            getNumericInputBackgroundColor &&
            getNumericInputBackgroundColor(row)
          "
          [alignValue]="column.justifyTo"
          [isDirty]="dirtyFields?.includes(column.field + '-' + rowIndex)"
          (onFocus)="onFieldFocus($event)"
          (onChange)="changeFieldValue($event)"
          (onFocusOut)="onFieldFocusOut()"
          (onArrowDown)="onArrowDown($event)"
        ></app-numeric-input>
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-row
        let-rowIndex="rowIndex"
        *ngIf="column.type === 'enum'"
      >
        <app-select
          class="select-default"
          [style]="{
            'background-color': backgroundColors
              ? backgroundColors[row[column.field]]
              : 'default'
          }"
          [ngClass]="{ 'select-full-height': column.setFullHeight }"
          [id]="column.field + '-' + rowIndex"
          [selectOptions]="context.optionSet[column.optionSetKey]"
          [hasEmptyRow]="selectHasEmptyRow"
          [dataItem]="row"
          [fieldName]="column.field"
          [filterSelectOption]="
            filteredSelectOptionKeys?.includes(column.optionSetKey)
              ? filterSelectOption
              : null
          "
          [color]="backgroundColors && backgroundColors[row[column.field]] ? optionSetTextColor : 'black'"
          [useDefaultColorInOptionList]="useDefaultColorInOptionList"
          [isDirty]="dirtyFields?.includes(column.field + '-' + rowIndex)"
          [saveEvent]="saveEvent"
          (onChange)="onSelectChange($event, column.optionSetKey)"
          (onFocus)="onFieldFocus($event)"
          (onFocusOut)="onFieldFocusOut()"
          (onArrowDown)="onArrowDown($event)"
        ></app-select>
      </ng-template>

      <ng-template
        *ngIf="aggregateResult && aggregateResult[column.field]"
        kendoGridFooterTemplate
      >
        <div class="grid-footer">
          <span>
            {{ aggregateResult[column.field].sum }}
          </span>
        </div>
      </ng-template>
    </kendo-grid-column>
  </ng-container>
</kendo-grid>
