import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import {
  AggregateDescriptor,
  AggregateResult,
  State,
  aggregateBy,
  process,
} from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { skipWhile, takeUntil, tap } from "rxjs/operators";
import { CardsNames, confidaneColors } from "src/app/app.model";
import { AppState } from "src/core/app.state";
import { AuthService } from "src/core/auth/auth.service";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import { FilterRelatedNames, Filters } from "src/core/models/filters.model";
import { SelectOption } from "src/core/models/select-option";
import {
  CreateRollingContractDto,
  RollingContractDto,
  WeekDto,
} from "src/core/services/api/api-clients";
import { DateTimeService } from "src/core/services/date-time.service";
import { FiltersService } from "src/core/services/filters.service";
import { loadCalendars } from "src/core/stores/calendar-store/calendar.actions";
import {
  getCalendarGridForRollingContracts,
  getCalendars,
} from "src/core/stores/calendar-store/calendar.selectors";
import {
  createRollingContract,
  fillRollingContractTillEndOfMonth,
  loadConfidence,
  loadContractedEmployees,
  loadContractedProjects,
  loadEmployees,
  loadProjects,
  loadRollingContracts,
  loadTeams,
  updateRollingContract,
} from "src/core/stores/contracts-store/contracts.actions";
import {
  getFilteredRollingContracts,
  getRollingAutoCompleteOptions,
  getRollingContractOptions,
  getRollingContractsViewFilterOptions,
} from "src/core/stores/contracts-store/contracts.selectors";
import { GridDefinition } from "src/shared/components/grid/grid-definition";
import { MultiFillDialogComponent } from "./multi-fill-dialog/multi-fill-dialog.component";
import { MultiFillDialogConfig } from "./multi-fill-dialog/multi-fill-dialog.model";
import { NewRollingContractDialogConfig } from "./new-rolling-contract-dialog/new-rolling-contract-dialog/new-rolling-contract-dialog-config.model";
import { NewRollingContractDialogComponent } from "./new-rolling-contract-dialog/new-rolling-contract-dialog/new-rolling-contract-dialog.component";
import { ContractedRollingGridField } from "./rolling-contracts.model";

export const ROLLING_PATH = "rolling";

@Component({
  selector: "app-rolling-contracts",
  templateUrl: "./rolling-contracts.component.html",
  styleUrls: ["./rolling-contracts.component.scss"],
})
export class RollingContractsComponent
  extends SubscribedContainer
  implements OnInit
{
  filters: Filters = {
    [FilterRelatedNames.visibility]: true,
    [FilterRelatedNames.year]: this._dateTimeService.getCurrentYearAsString(),
    [FilterRelatedNames.team]: [],
    [FilterRelatedNames.projectName]: [],
    [FilterRelatedNames.confidence]: [],
    [FilterRelatedNames.rolling]: [],
  };

  grid$: Observable<GridDefinition> = this._store.select(
    getCalendarGridForRollingContracts(this.filters.year)
  );

  data$: Observable<RollingContractDto[]> = this.getData();

  optionSets$: Observable<{ [key: string]: SelectOption[] }> =
    this._store.select(getRollingContractOptions);

  filtersOptions$ = this.getFilters();

  form: FormGroup = new FormGroup({
    [FilterRelatedNames.visibility]: new FormControl(true),
    [FilterRelatedNames.year]: new FormControl(
      this._dateTimeService.getCurrentYearAsString()
    ),
    [FilterRelatedNames.team]: new FormControl([]),
    [FilterRelatedNames.projectName]: new FormControl([]),
    [FilterRelatedNames.confidence]: new FormControl([]),
    [FilterRelatedNames.rolling]: new FormControl([]),
  });

  autocompleteOptions$ = this._store.select(getRollingAutoCompleteOptions);

  editedDataItem: any = null;
  selectedDataItem: any = null;
  lastClickedFieldName: string | null = null;
  inputPattern = "^[0-9]{0,4}[.]?((?<=.)[0-9]{0,2})?$";
  weekId: string | null = null;
  hiddenColumns = {} as Record<string, boolean>;
  cardsNames = CardsNames;
  optionNames = FilterRelatedNames;
  displayedProjects: string[] = [];
  displayedOpportunities: string[] = [];
  fillMultipleEvent = new EventEmitter();
  aggregateResult: AggregateResult;
  aggregateDescriptor: AggregateDescriptor[] = [];
  aggregateState: State = {
    filter: {
      logic: "and",
      filters: [],
    },
  };

  constructor(
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _dateTimeService: DateTimeService,
    private _filterService: FiltersService,
    private _changeDetectoreRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this._store.dispatch(loadContractedEmployees());
    this._store.dispatch(loadCalendars());

    this._store
      .select(getCalendars)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((calendars: WeekDto[]) => {
        calendars.forEach((item) => {
          this.aggregateDescriptor.push({ field: item.name, aggregate: "sum" });
        });
      });

    this._store.dispatch(loadRollingContracts());
    this._store.dispatch(loadContractedProjects());
    this._store.dispatch(loadTeams());
    this._store.dispatch(loadConfidence());
    this._store.dispatch(loadEmployees());
    this._store.dispatch(loadProjects());

    this.setHiddenColumns();
  }

  openCreateRollingContractDialog() {
    this._changeDetectoreRef.detach();

    const dialogRef = this.dialog.open(NewRollingContractDialogComponent, {
      data: {
        onConfirm: (createRollingContractDto: CreateRollingContractDto) =>
          this._store.dispatch(
            createRollingContract({ createRollingContractDto })
          ),
      } as NewRollingContractDialogConfig,
    });

    this.reattachChangeDetectore(dialogRef);
  }

  openMultiFillDialog() {
    this._changeDetectoreRef.detach();
    let dataItem = this.editedDataItem
      ? this.editedDataItem
      : this.selectedDataItem;

    setTimeout(() => {
      const dialogRef = this.dialog.open(MultiFillDialogComponent, {
        data: {
          onFill: (rollingContract: RollingContractDto) =>
            this.saveUpdates(rollingContract),
          choosedWeekNumber: this.lastClickedFieldName?.replace("w", ""),
          dataItem,
        } as MultiFillDialogConfig,
      });

      this.reattachChangeDetectore(dialogRef);
    });
  }

  // Temporary solution: function is triggered by mousedown event directly
  // on the app-button element and not by the onClick event emitted by ButtonComponent - emitted events
  // are 'too slow' and are overridden by the focusout event triggered inside GridComponent.
  fillEndOfTheMonth() {
    if (this._authService.canUserWrite()) {
      let dataItem = this.editedDataItem
        ? this.editedDataItem
        : this.selectedDataItem;

      setTimeout(() => {
        let data = {
          calendarId: this.weekId,
          rollingContract: dataItem,
          value: dataItem[this.lastClickedFieldName],
        };

        this._store.dispatch(fillRollingContractTillEndOfMonth(data));
        this.lastClickedFieldName = null;
        this.editedDataItem = null;
        this.selectedDataItem = null;
        this.fillMultipleEvent.emit();
      });
    }
  }

  saveUpdates(rollingContract: RollingContractDto) {
    if (this._authService.canUserWrite()) {
      this._store.dispatch(updateRollingContract({ rollingContract }));
    }

    this.lastClickedFieldName = null;
    this.editedDataItem = null;
    this.selectedDataItem = null;
  }

  updateFilters() {
    this.filters = this._filterService.updateFilter(this.filters, this.form);
    this.setHiddenColumns();
    
    this.grid$ = this._store.select(
      getCalendarGridForRollingContracts(this.filters.year)
    );

    this.data$ = this.getData();
  }

  // Temporary solution: function is triggered by mousedown event directly
  // on the app-button element and not by the onClick event emitted by ButtonComponent - emitted events
  // are 'too slow' and are overridden by the focusout event triggered inside GridComponent.
  create() {
    if (this._authService.canUserWrite()) {
      console.log("creating...");
    }
  }

  setWeekId(weekId: string) {
    this.weekId = weekId;
  }

  filterSelectOption(
    value: string,
    selectOptions?: SelectOption[]
  ): SelectOption[] {
    if (!selectOptions || selectOptions.length <= 1) return [];

    let isValidOption = true;
    let filteredSelectOptions: SelectOption[] = [];

    selectOptions.forEach((option) => {
      if (isValidOption) {
        filteredSelectOptions.push(option);

        if (option.value === value) {
          isValidOption = false;
        }
      }
    });

    return filteredSelectOptions;
  }

  getNumericInputBackgroundColor(dataRow: any): string {
    return confidaneColors[
      dataRow[ContractedRollingGridField.confidenceSymbol]
    ];
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.aggregateState = state;
    this.data$
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((rollingContract) => {
        return process(rollingContract, this.aggregateState);
      });
  }

  private setHiddenColumns() {
    this.hiddenColumns = {
      [ContractedRollingGridField.year]:
        this.filters[FilterRelatedNames.year] !== undefined,
    };
  }

  private getData() {
    return this._store.select(getFilteredRollingContracts(this.filters)).pipe(
      skipWhile((value) => !value),
      tap((data) => {
        this.displayedProjects = data.map((contract) => contract.projectName);
        this.displayedOpportunities = data.map(
          (contract) => contract.opportunityName
        );
        this.filtersOptions$ = this.getFilters();

        process(data, this.aggregateState);
        this.aggregateResult = aggregateBy(data, this.aggregateDescriptor);
      })
    );
  }

  private getFilters() {
    return (this.filtersOptions$ = this._store
      .select(
        getRollingContractsViewFilterOptions(
          this.displayedProjects,
          this.displayedOpportunities
        )
      )
      .pipe(skipWhile((options) => !options)));
  }

  private reattachChangeDetectore(dialogRef: MatDialogRef<any>) {
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this._changeDetectoreRef.reattach();
        this._changeDetectoreRef.detectChanges();
      });
  }
}
