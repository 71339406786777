import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JustifyValue } from "../grid/grid-definition";
import {
  OnChangeSelectEmitObj,
  OnFocusSelectEmitObj,
} from "../select/select.model";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent {
  @Input() id: string;
  @Input() value: string;
  @Input() dataItem: any;
  @Input() fieldName: string;
  @Input() alignValue: JustifyValue = "left";
  @Input() isDirty: boolean = false;
  @Input() options: string[] | undefined;

  @Output() onChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onArrowDown = new EventEmitter();

  isFocused: boolean = false;

  focus() {
    this.isFocused = true;
    this.onFocus.emit({
      dataItem: this.dataItem,
      fieldName: this.fieldName,
    } as OnFocusSelectEmitObj);
  }

  focusOut() {
    this.isFocused = false;
    this.onFocusOut.emit();
  }

  change(event: any) {
    this.dataItem[this.fieldName] = event.target.value;

    let value = event.target.value;

    this.onChange.emit({
      id: this.id,
      fieldName: this.fieldName,
      dataItem: this.dataItem,
      value: value !== "" ? value : null,
    } as OnChangeSelectEmitObj);
  }
}
