import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DateTimeService {
  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  getCurrentYearAsString(): string {
    return `${this.getCurrentYear()}`;
  }
}
