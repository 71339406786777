export enum ArrowKeys {
  Up = "ArrowUp",
  Right = "ArrowRight",
  Down = "ArrowDown",
  Left = "ArrowLeft",
}

export enum FunctionKey {
  Enter = "Enter",
}
