<div class="input-container" [tabIndex]="id" (focus)="focus()">
  <span
    [title]="value"
    class="input-default"
    [style]="{
      'font-weight': isDirty ? '800' : 'normal'
    }"
    *ngIf="!isFocused"
    >{{ value }}</span
  >
  <input
    *ngIf="isFocused"
    type="text"
    [value]="value"
    list="options"
    [style]="{
      'text-align': alignValue,
      'font-weight': isDirty ? '800' : 'normal'
    }"
    class="input-default"
    (focusout)="focusOut()"
    (input)="change($event)"
  />
  <datalist id="options" *ngIf="isFocused && options">
    <option *ngFor="let option of options" [value]="option"></option>
  </datalist>
</div>
