import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AutocompleteOption } from "./autocomplete.model";
import { isValidAutocompleteValue } from "./autocomplete.validators";

@Component({
  selector: "app-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit {
  @Input() placeholder = "";
  @Input() label = "";
  @Input() options = [] as AutocompleteOption[];
  @Input() required = false;
  @Input() control: FormControl | undefined;
  @Input() type: "text" | "number" = "text";

  filteredOptions: Observable<AutocompleteOption[]>;

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(""),
      map((value) => (this.options.length > 0 ? this.filter(value || "") : []))
    );

    this.control.addValidators(isValidAutocompleteValue(this.options));
  }

  private filter(value: string): AutocompleteOption[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.value.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
}
