import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DropdownOption } from "./dropdownOption";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent {
  @Input() placeholder: string;
  @Input() options: DropdownOption[] = [];
  @Input() control: FormControl | undefined;
  @Input() isMultiselect: boolean = false;
  @Input() outline: boolean = true;
  @Input() required: boolean = false;

  @Output() onChange = new EventEmitter();
  @Output() onClose = new EventEmitter();

  selectionChanged() {
    if (this.onChange.observers.length > 0) {
      this.onChange.emit();
    }
  }

  multipleChanged() {
    if (this.onClose.observers.length > 0) {
      this.onClose.emit();
    }
  }
}
