import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, skipWhile, tap } from "rxjs/operators";
import { CardsNames, teamColors } from "src/app/app.model";
import { AppState } from "src/core/app.state";
import { AuthService } from "src/core/auth/auth.service";
import {
  FilterRelatedNames,
  Filters,
  FiltersOptions,
} from "src/core/models/filters.model";
import { SelectOption } from "src/core/models/select-option";
import { ContractedEmployeeDto } from "src/core/services/api/api-clients";
import { DateTimeService } from "src/core/services/date-time.service";
import { FiltersService } from "src/core/services/filters.service";
import { GridService } from "src/core/services/grid.service";
import { loadCalendars } from "src/core/stores/calendar-store/calendar.actions";
import { getCalendarGridForContractedEmployees } from "src/core/stores/calendar-store/calendar.selectors";
import {
  loadContractedEmployees,
  loadTeams,
  updateContractedEmployee,
} from "src/core/stores/contracts-store/contracts.actions";
import {
  getContractedEmployeeViewFilterOptions,
  getFilteredContractedEmployees,
  getTeamsAsOptionSet,
} from "src/core/stores/contracts-store/contracts.selectors";
import {
  GridDefinition,
  optionSetKeys,
} from "src/shared/components/grid/grid-definition";
import { ContractedEmployeeGridField } from "./employees-contracts.model";

export const EMPLOYEES_PATH = "employees";

@Component({
  selector: "app-employees-contracts",
  templateUrl: "./employees-contracts.component.html",
  styleUrls: ["./employees-contracts.component.scss"],
})
export class EmployeesContractsComponent implements OnInit {
  filters: Filters = {
    [FilterRelatedNames.visibility]: true,
    [FilterRelatedNames.year]: this._dateTimeService.getCurrentYearAsString(),
    [FilterRelatedNames.team]: [],
    [FilterRelatedNames.employeeName]: [],
  };

  grid$: Observable<GridDefinition> = this._store.select(
    getCalendarGridForContractedEmployees(this.filters.year)
  );

  data$: Observable<ContractedEmployeeDto[]> = this.getData();

  filtersOptions$: Observable<FiltersOptions> = this.getFilters();

  form: FormGroup = new FormGroup({
    [FilterRelatedNames.visibility]: new FormControl(true),
    [FilterRelatedNames.year]: new FormControl(
      this._dateTimeService.getCurrentYearAsString()
    ),
    [FilterRelatedNames.team]: new FormControl([]),
    [FilterRelatedNames.employeeName]: new FormControl([]),
  });

  teams$: Observable<{ [key: string]: SelectOption[] }> = this._store
    .select(getTeamsAsOptionSet)
    .pipe(map((value) => ({ [optionSetKeys.teams]: value })));

  teamsBackgroundColor = teamColors;

  editedDataItem: any = null;
  selectedDataItem: any = null;
  lastClickedFieldName: string | null = null;
  hiddenColumns = {} as Record<string, boolean>;
  cardsNames = CardsNames;
  optionNames = FilterRelatedNames;
  displayedEmployees: string[] = [];
  fillTillEndEvent = new EventEmitter();

  constructor(
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _filterService: FiltersService,
    private _dateTimeService: DateTimeService,
    private _gridService: GridService
  ) {}

  ngOnInit(): void {
    this._store.dispatch(loadCalendars());
    this._store.dispatch(loadContractedEmployees());
    this._store.dispatch(loadTeams());

    this.setHiddenColumns();
  }

  saveUpdates(contractedEmployee: ContractedEmployeeDto) {
    if (this._authService.canUserWrite()) {
      this._store.dispatch(updateContractedEmployee({ contractedEmployee }));
    }
  }

  updateFilters() {
    this.filters = this._filterService.updateFilterWithTeamYearDependencies(
      this.filters,
      this.form
    );

    this.setHiddenColumns();

    this.grid$ = this._store.select(
      getCalendarGridForContractedEmployees(this.filters.year)
    );

    this.data$ = this.getData();
  }

  // Temporary solution: function is triggered by mousedown event directly
  // on the app-button element and not by the onClick event emitted by ButtonComponent - emitted events
  // are 'too slow' and are overridden by the focusout event triggered inside GridComponent.
  fillTillEnd() {
    const dataItem = this._gridService.fillTillEnd(
      this.editedDataItem,
      this.selectedDataItem,
      this.lastClickedFieldName
    );

    this.saveUpdates(dataItem);
    this.editedDataItem = null;
    this.fillTillEndEvent.emit();
  }

  private setHiddenColumns() {
    this.hiddenColumns = {
      [ContractedEmployeeGridField.year]:
        this.filters[FilterRelatedNames.year] !== undefined,
    };
  }

  private getData() {
    return this._store
      .select(getFilteredContractedEmployees(this.filters))
      .pipe(
        skipWhile((value) => !value),
        tap((data) => {
          this.displayedEmployees = data.map(
            (employee) => employee.employeeName
          );
          this.filtersOptions$ = this.getFilters();
        })
      );
  }

  private getFilters() {
    return (this.filtersOptions$ = this._store
      .select(getContractedEmployeeViewFilterOptions(this.displayedEmployees))
      .pipe(skipWhile((options) => !options)));
  }
}
