import { FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { AutocompleteOption } from "./autocomplete.model";

export function isValidAutocompleteValue(
  options: AutocompleteOption[]
): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    if (options.length > 0 && value !== "") {
      const option = options.find(
        (item) => item.value.toLowerCase() === value.toLowerCase()
      );

      if (option === undefined) {
        return { invalidValue: true };
      }
    }
    return null;
  };
}
