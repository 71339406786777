export interface ColorsBase {
  default: string;
}

export const teamColors = {
  ARP: "#7febf0",
  HR: "#7febf0",
  IT: "#7febf0",
  PROD: "#7febf0",
  RED: "#ff7575",
  ENGINE: "#8e73f6",
  TALENTS: "#ff9c00",
  YELLOW: "#fdd24e",
  BLUE: "#5880ff",
  TECH: "#8f8f8f",
  GREEN: "#9aeb9a",
  EXPANSION: "#fb8ed3",
  default: "#000000",
};

export const confidaneColors = {
  A: "#0e8261",
  B: "#14b587",
  C: "#6de3ba",
};

export enum CardsNames {
  employeeContracts = "Employees Contracts",
  projectContracts = "Projects Contracts",
  rollingContracts = "Rolling Contracts",
}
