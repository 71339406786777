import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import { getSelectedSidebarItemName } from "src/core/stores/layout-store/layout.selectors";
import { AppState } from "../core/app.state";
import {} from "../core/stores/layout-store/layout.actions";
import {} from "../core/stores/layout-store/layout.selectors";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends SubscribedContainer implements OnInit {
  selectedSidebarItemName$ = this._store.pipe(
    select(getSelectedSidebarItemName)
  );
  isIframe = false;

  constructor(private _store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
