import { WeekDto } from "../../services/api/api-clients";

export const calendarStoreName = "calendarStore";

export interface CalendarState {
  calendars: WeekDto[];
  currentWeekNumber: number;
  yearsRange: number[];
}

export const initialCalendarState: CalendarState = {
  calendars: [],
  currentWeekNumber: 0,
  yearsRange: [],
};
