import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  loadCalendars,
  loadCalendarsFailure,
  loadCalendarsSuccess,
} from "./calendar.actions";
import { catchError, mergeMap, switchMap, take } from "rxjs/operators";
import { of } from "rxjs";
import { CalendarClient } from "src/core/services/api/api-clients";

@Injectable()
export class CalendarEffects {
  constructor(
    private _actions$: Actions,
    private _calendarClient: CalendarClient
  ) {}

  loadCalendarsEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadCalendars),
      take(1),
      switchMap(() =>
        this._calendarClient.getCalendar().pipe(
          mergeMap((calendarDto) => [loadCalendarsSuccess({ calendarDto })]),
          catchError((error) => of(loadCalendarsFailure({ error })))
        )
      )
    );
  });
}
