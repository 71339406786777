import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-dialog-container",
  templateUrl: "./dialog-container.component.html",
  styleUrls: ["./dialog-container.component.scss"],
})
export class DialogContainerComponent implements OnInit {
  @Input() title: string = "";
  @Input() confirmButtonName = "Confirm";
  @Input() disableConfirmButton: boolean = false;
  @Output() onMouseDownEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onMouseDownHandler() {
    this.onMouseDownEvent.emit();
  }
}
