import { CreateRollingContractDto } from "src/core/services/api/api-clients";

export class NewRollingContractDialogConfig {
  onConfirm: (createRolling: CreateRollingContractDto) => void;
}

export enum RollingDialogFieldNames {
  opportunity = "opportunity",
  team = "team",
  year = "year",
  contractedProject = "contractedProject",
  contractedEmployee = "contractedEmployee",
  confidence = "confidence",
}
