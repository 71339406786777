import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export function isValidWeek(currentWeekNumber: number): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    return control.value < currentWeekNumber
      ? { invalidWeekNumber: true }
      : null;
  };
}

export function validateRangeAndInteger(
  fieldNameOfSmallerValue: string,
  fieldNameOfGreaterValue: string,
  maxValue: number
) {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const fieldOfSmallerValue = formGroup.get(
      fieldNameOfSmallerValue
    ) as FormControl;
    const fieldOfGreaterValue = formGroup.get(fieldNameOfGreaterValue);
    const minValue = fieldOfSmallerValue.errors?.min?.min;

    fieldOfGreaterValue.setErrors(null);

    if (
      fieldOfSmallerValue?.value !== "" &&
      fieldOfGreaterValue?.value !== "" &&
      fieldOfGreaterValue?.value !== null
    ) {
      const smallerValue = +fieldOfSmallerValue.value;
      const greaterValue = +fieldOfGreaterValue.value;
      const floatNumberError =
        greaterValue % 1 !== 0
          ? {
              isFloatNumber: true,
            }
          : undefined;

      if (
        (greaterValue < smallerValue ||
          greaterValue < minValue ||
          greaterValue === 0) &&
        smallerValue <= maxValue
      ) {
        fieldOfGreaterValue.setErrors({
          min: {
            min:
              fieldOfSmallerValue.errors?.min?.min ?? smallerValue === 0
                ? 1
                : smallerValue,
            actual: greaterValue,
          },
          ...floatNumberError,
        });
      } else if (greaterValue > maxValue) {
        fieldOfGreaterValue.setErrors({
          max: {
            max: maxValue,
            actual: greaterValue,
          },
          ...floatNumberError,
        });
      } else if (floatNumberError) {
        fieldOfGreaterValue.setErrors(floatNumberError);
      }
    }

    return null;
  };
}
