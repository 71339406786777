import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ArrowKeys, FunctionKey } from "src/shared/shared.model";
import { JustifyValue } from "../grid/grid-definition";
import {
  OnChangeSelectEmitObj,
  OnFocusSelectEmitObj,
  OnKeyDownEmitObj,
} from "../select/select.model";

@Component({
  selector: "app-numeric-input",
  templateUrl: "./numeric-input.component.html",
  styleUrls: ["./numeric-input.component.scss"],
})
export class NumericInputComponent {
  @Input() id: string;
  @Input() value: number = 0;
  @Input() dataItem: any;
  @Input() fieldName: string;
  @Input() inputPattern: string = "d*";
  @Input() isZeroChangedToNull: boolean = false;
  @Input() backgroundColor: string = "inherit";
  @Input() alignValue: JustifyValue = "center";
  @Input() isDirty: boolean = false;

  @Output() onChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onArrowDown = new EventEmitter();

  focus() {
    this.onFocus.emit({
      dataItem: this.dataItem,
      fieldName: this.fieldName,
    } as OnFocusSelectEmitObj);
  }

  focusOut() {
    if (this.isZeroChangedToNull && this.dataItem[this.fieldName] === 0) {
      this.dataItem[this.fieldName] = null;
    }

    this.onFocusOut.emit();
  }

  onKeyDownHandler(event: KeyboardEvent) {
    if (
      event.key === ArrowKeys.Up ||
      event.key === ArrowKeys.Right ||
      event.key === ArrowKeys.Down ||
      event.key === ArrowKeys.Left ||
      event.key === FunctionKey.Enter
    ) {
      event.preventDefault();
      this.onArrowDown.emit({
        id: this.id,
        key: event.key === FunctionKey.Enter ? ArrowKeys.Right : event.key,
      } as OnKeyDownEmitObj);
    }
  }

  change(event: any) {
    this.dataItem[this.fieldName] = Number(event.target.value);

    let value = event.target.value;

    this.onChange.emit({
      id: this.id,
      fieldName: this.fieldName,
      dataItem: this.dataItem,
      value: value !== "" ? value : null,
    } as OnChangeSelectEmitObj);
  }
}
