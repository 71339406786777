import { createAction, props } from "@ngrx/store";
import { CalendarDto } from "../../services/api/api-clients";

export enum CalendarActionTypes {
  loadCalendars = "[Calendar] Load Calendar",
  loadCalendarsSuccess = "[Calendar] Load Calendar - Success",
  loadCalendarsFailure = "[Calendar] Load Calendar - Failure",
}

export const loadCalendars = createAction(CalendarActionTypes.loadCalendars);

export const loadCalendarsSuccess = createAction(
  CalendarActionTypes.loadCalendarsSuccess,
  props<{ calendarDto: CalendarDto}>()
);

export const loadCalendarsFailure = createAction(
  CalendarActionTypes.loadCalendarsFailure,
  props<{ error: any }>()
);
