import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { BusyIndicatorComponent } from "./components/busy-indicator/busy-indicator.component";
import { ButtonComponent } from "./components/button/button.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { DialogContainerComponent } from "./components/dialog-container/dialog-container.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { GridComponent } from "./components/grid/grid.component";
import { HeaderComponent } from "./components/header/header.component";
import { IconComponent } from "./components/icon/icon.component";
import { NavListComponent } from "./components/nav-list/nav-list.component";
import { NumericInputComponent } from "./components/numeric-input/numeric-input.component";
import { SelectComponent } from "./components/select/select.component";
import { MaterialModule } from "./material.module";
import { DeepCopyPipe } from "./pipes/deepCopy.pipe";
import { SwitchBoxComponent } from "./components/switch-box/switch-box.component";
import { TextInputComponent } from './components/text-input/text-input.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    BusyIndicatorComponent,
    DeepCopyPipe,
    NavListComponent,
    IconComponent,
    ButtonComponent,
    DropdownComponent,
    HeaderComponent,
    GridComponent,
    SelectComponent,
    NumericInputComponent,
    AutocompleteComponent,
    DialogContainerComponent,
    SwitchBoxComponent,
    TextInputComponent,
  ],
  exports: [
    ConfirmDialogComponent,
    BusyIndicatorComponent,
    DeepCopyPipe,
    NavListComponent,
    ButtonComponent,
    DropdownComponent,
    HeaderComponent,
    AutocompleteComponent,
    MaterialModule,
    GridComponent,
    SelectComponent,
    DialogContainerComponent,
    SwitchBoxComponent,
  ],
  entryComponents: [ConfirmDialogComponent],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
