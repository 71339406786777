<select
  [id]="id"
  class="grid-option-set"
  (change)="onChangeHandler($event)"
  (focus)="onFocusHandler()"
  (focusout)="onFocusOutHandler()"
  (mousedown)="onMouseDownHandler()"
  (keydown)="onKeyDownHandler($event)"
  [disabled]="
    filteredSelectOptions.length <= 1 ||
    filteredSelectOptions[0] === dataItem[fieldName]
  "
  [ngStyle]="{
    color: selectedOptionColor,
    'font-weight': isDirty ? '800' : 'normal'
  }"
>
  <option *ngIf="hasEmptyRow" value=""></option>
  <option
    *ngFor="let option of filteredSelectOptions"
    [value]="option.value"
    [style]="{
      color: useDefaultColorInOptionList ? selectedOptionColor : option.color
    }"
    [selected]="option.value === dataItem[fieldName]"
    [ngStyle]="{
      display:
        filterSelectOption?.length === 1 && option.value === dataItem[fieldName]
          ? 'none'
          : 'block'
    }"
  >
    {{ isOpen ? option.displayText : option.shortText }}
  </option>
</select>
