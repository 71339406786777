import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { GridModule } from "@progress/kendo-angular-grid";
import { CoreModule } from "src/core/core.module";
import { MaterialModule } from "src/shared/material.module";
import { SharedModule } from "src/shared/shared.module";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EmployeesContractsComponent } from "./contracts/employees-contracts/employees-contracts.component";
import { ProjectsContractsComponent } from "./contracts/projects-contracts/projects-contracts.component";
import { MultiFillDialogComponent } from "./contracts/rolling-contracts/multi-fill-dialog/multi-fill-dialog.component";
import { NewRollingContractDialogComponent } from "./contracts/rolling-contracts/new-rolling-contract-dialog/new-rolling-contract-dialog/new-rolling-contract-dialog.component";
import { RollingContractsComponent } from "./contracts/rolling-contracts/rolling-contracts.component";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    EmployeesContractsComponent,
    ProjectsContractsComponent,
    RollingContractsComponent,
    NewRollingContractDialogComponent,
    MultiFillDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    MaterialModule,
    GridModule,
    CoreModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
