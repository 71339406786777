<app-dialog-container
  title="Create rolling contract"
  confirmButtonName="Create"
  [disableConfirmButton]="disableCreateButton"
  (onMouseDownEvent)="confirm()"
>
  <div class="dialog-rolling-content" *ngIf="formOptions as options">
    <div class="content-left">
      <app-autocomplete
        placeholder="Enter Opportunity Name"
        label="Opportunity Name"
        [options]="[]"
        [control]="formGroup.get(formNames.opportunity)"
        [required]="true"
      ></app-autocomplete>
      <app-dropdown
        placeholder="Team"
        [options]="options[formNames.team]"
        [control]="formGroup.get(formNames.team)"
        [outline]="false"
        [required]="true"
      ></app-dropdown>
      <app-dropdown
        placeholder="Year"
        [options]="yearOptions"
        [control]="formGroup.get(formNames.year)"
        [outline]="false"
        [required]="true"
      ></app-dropdown>
    </div>
    <div class="content-right">
      <app-autocomplete
        placeholder="Enter Project Name"
        label="Project Name"
        [options]="options[formNames.contractedProject]"
        [control]="formGroup.get(formNames.contractedProject)"
      ></app-autocomplete>
      <app-autocomplete
        placeholder="Enter Employee Name"
        label="Employee Name"
        [options]="options[formNames.contractedEmployee]"
        [control]="formGroup.get(formNames.contractedEmployee)"
      ></app-autocomplete>
      <app-dropdown
        placeholder="Probability"
        [options]="options[formNames.confidence]"
        [control]="formGroup.get(formNames.confidence)"
        [outline]="false"
        [required]="true"
      ></app-dropdown>
    </div>
  </div>
</app-dialog-container>
