import {
  Confidence,
  ContractedEmployeeDto,
  ContractedProjectDto,
  Employee,
  Project,
  RollingContractDto,
  TeamDto,
} from "src/core/services/api/api-clients";

export const contractsStoreName = "contracts";

export interface ContractsState {
  contractedEmployees: Record<number, ContractedEmployeeDto>;
  teams: TeamDto[];
  contractedProjects: Record<number, ContractedProjectDto>;
  rollingContracts: Record<number, RollingContractDto>;
  confidences: Confidence[];
  employees: Record<number, Employee>;
  projects: Record<string, Project>;
}

export const initialContractsState: ContractsState = {
  contractedEmployees: [],
  teams: [],
  contractedProjects: [],
  rollingContracts: [],
  confidences: [],
  employees: [],
  projects: {},
};
