import { createAction, props } from "@ngrx/store";
import {
  Confidence,
  ContractedEmployeeDto,
  ContractedProjectDto,
  CreateRollingContractDto,
  Employee,
  Project,
  RollingContractDto,
  TeamDto,
} from "src/core/services/api/api-clients";

export enum ContractsActionTypes {
  loadContractedEmployees = "[ContractedEmployee] Load Contracted Employees",
  loadContractedEmployeesSuccess = "[ContractedEmployee] Load Contracted Employees - Success",
  loadContractedEmployeesFailure = "[ContractedEmployee] Load Contracted Employees - Failure",
  loadContractedProjects = "[ContractedProject] Load Contracted Projects",
  loadContractedProjectsSuccess = "[ContractedProject] Load Contracted Projects - Success",
  loadContractedProjectsFailure = "[ContractedProject] Load Contracted Projects - Failure",
  loadTeams = "[Teams] Load Teams",
  loadTeamsSuccess = "[Teams] Load Teams - Success",
  loadTeamsFailure = "[Teams] Load Teams - Failure",
  loadRollingContracts = "[Rolling Contracts] Load Rolling Contracts",
  loadRollingContractsSuccess = "[Rolling Contracts] Load Rolling Contracts - Success",
  loadRollingContractsFailure = "[Rolling Contracts] Load Rolling Contracts - Failure",
  loadConfidence = "[Confidence] Load Confidence",
  loadConfidenceSuccess = "[Confidence] Load Confidence - Success",
  loadConfidenceFailure = "[Confidence] Load Confidence - Failure",
  loadEmployees = "[Employees] Load Employees",
  loadEmployeesSuccess = "[Employees] Load Employees - Success",
  loadEmployeesFailure = "[Employees] Load Employees - Failure",
  loadProjects = "[Projects] Load Projects",
  loadProjectsSuccess = "[Projects] Load Projects - Success",
  loadProjectsFailure = "[Projects] Load Projects - Failure",
  updateContractedEmployee = "[ContractedEmployee] Update Contracted Employee",
  updateContractedEmployeeSuccess = "[ContractedEmployee] Update Contracted Employee - Success",
  updateContractedEmployeeFailure = "[ContractedEmployee] Update Contracted Employee - Failure",
  updateContractedProject = "[ContractedProject] Update Contracted Project",
  updateContractedProjectSuccess = "[ContractedProject] Update Contracted Project - Success",
  updateContractedProjectFailure = "[ContractedProject] Update Contracted Project - Failure",
  createRollingContract = "[RollingContract] Create Rolling Contract",
  createRollingContractSuccess = "[RollingContract] Create Rolling Contract - Success",
  createRollingContractFailure = "[RollingContract] Create Rolling Contract - Failure",
  updateRollingContract = "[RollingContract] Update Rolling Contract",
  updateRollingContractSuccess = "[RollingContract] Update Rolling Contract - Success",
  updateRollingContractFailure = "[RollingContract] Update Rolling Contract - Failure",
  fillRollingContractTillEndOfMonth = "[RollingContract] Fill Rolling Contract till end of month",
}

export const loadContractedEmployees = createAction(
  ContractsActionTypes.loadContractedEmployees
);
export const loadContractedEmployeesSuccess = createAction(
  ContractsActionTypes.loadContractedEmployeesSuccess,
  props<{ contractedEmployees: Record<number, ContractedEmployeeDto> }>()
);
export const loadContractedEmployeesFailure = createAction(
  ContractsActionTypes.loadContractedEmployeesFailure,
  props<{ error: any }>()
);

export const loadContractedProjects = createAction(
  ContractsActionTypes.loadContractedProjects
);
export const loadContractedProjectsSuccess = createAction(
  ContractsActionTypes.loadContractedProjectsSuccess,
  props<{ contractedProjects: Record<number, ContractedProjectDto> }>()
);
export const loadContractedProjectsFailure = createAction(
  ContractsActionTypes.loadContractedProjectsFailure,
  props<{ error: any }>()
);

export const loadTeams = createAction(ContractsActionTypes.loadTeams);
export const loadTeamsSuccess = createAction(
  ContractsActionTypes.loadTeamsSuccess,
  props<{ teams: TeamDto[] }>()
);
export const loadTeamsFailure = createAction(
  ContractsActionTypes.loadTeamsFailure,
  props<{ error: any }>()
);

export const loadRollingContracts = createAction(
  ContractsActionTypes.loadRollingContracts
);

export const loadRollingContractsSuccess = createAction(
  ContractsActionTypes.loadRollingContractsSuccess,
  props<{ rollingContracts: Record<number, RollingContractDto> }>()
);
export const loadRollingContractsFailure = createAction(
  ContractsActionTypes.loadRollingContractsFailure,
  props<{ error: any }>()
);

export const loadConfidence = createAction(ContractsActionTypes.loadConfidence);

export const loadConfidenceSuccess = createAction(
  ContractsActionTypes.loadConfidenceSuccess,
  props<{ confidences: Confidence[] }>()
);
export const loadConfidenceFailure = createAction(
  ContractsActionTypes.loadConfidenceFailure,
  props<{ error: any }>()
);

export const loadEmployees = createAction(ContractsActionTypes.loadEmployees);

export const loadEmployeesSuccess = createAction(
  ContractsActionTypes.loadEmployeesSuccess,
  props<{ employees: Record<number, Employee> }>()
);
export const loadEmployeesFailure = createAction(
  ContractsActionTypes.loadEmployeesFailure,
  props<{ error: any }>()
);

export const loadProjects = createAction(ContractsActionTypes.loadProjects);

export const loadProjectsSuccess = createAction(
  ContractsActionTypes.loadProjectsSuccess,
  props<{ projects: Record<number, Project> }>()
);
export const loadProjectsFailure = createAction(
  ContractsActionTypes.loadProjectsFailure,
  props<{ error: any }>()
);

export const updateContractedEmployee = createAction(
  ContractsActionTypes.updateContractedEmployee,
  props<{ contractedEmployee: ContractedEmployeeDto }>()
);

export const updateContractedEmployeeSuccess = createAction(
  ContractsActionTypes.updateContractedEmployeeSuccess,
  props<{ updatedEmployee: ContractedEmployeeDto }>()
);

export const updateContractedEmployeeFailure = createAction(
  ContractsActionTypes.updateContractedEmployeeFailure,
  props<{ error: any }>()
);

export const updateContractedProject = createAction(
  ContractsActionTypes.updateContractedProject,
  props<{ contractedProject: ContractedProjectDto }>()
);

export const updateContractedProjectSuccess = createAction(
  ContractsActionTypes.updateContractedProjectSuccess,
  props<{ updatedProject: ContractedProjectDto }>()
);

export const updateContractedProjectFailure = createAction(
  ContractsActionTypes.updateContractedProjectFailure,
  props<{ error: any }>()
);

export const createRollingContract = createAction(
  ContractsActionTypes.createRollingContract,
  props<{ createRollingContractDto: CreateRollingContractDto }>()
);

export const createRollingContractSuccess = createAction(
  ContractsActionTypes.createRollingContractSuccess,
  props<{ rollingContract: RollingContractDto }>()
);

export const createRollingContractFailure = createAction(
  ContractsActionTypes.createRollingContractFailure,
  props<{ error: any }>()
);

export const updateRollingContract = createAction(
  ContractsActionTypes.updateRollingContract,
  props<{ rollingContract: RollingContractDto }>()
);

export const updateRollingContractSuccess = createAction(
  ContractsActionTypes.updateRollingContractSuccess,
  props<{ updatedRollingContract: RollingContractDto }>()
);

export const updateRollingContractFailure = createAction(
  ContractsActionTypes.updateRollingContractFailure,
  props<{ error: any }>()
);

export const fillRollingContractTillEndOfMonth = createAction(
  ContractsActionTypes.fillRollingContractTillEndOfMonth,
  props<{
    rollingContract: RollingContractDto;
    value: number;
    calendarId: string;
  }>()
);
