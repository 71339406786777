import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ColorsBase } from "src/app/app.model";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import { SelectOption } from "src/core/models/select-option";
import { ArrowKeys } from "src/shared/shared.model";
import {
  OnChangeSelectEmitObj,
  OnFocusSelectEmitObj,
  OnKeyDownEmitObj,
} from "./select.model";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent
  extends SubscribedContainer
  implements OnInit, OnChanges
{
  @Input() selectOptions: SelectOption[];

  @Input() id: string;
  @Input() hasEmptyRow: boolean = false;
  @Input() isDirty: boolean = false;
  @Input() dataItem: any;
  @Input() fieldName: string;
  @Input() colors?: ColorsBase;
  @Input() set color(value: string) {
    this.selectedOptionColor =
      value ?? this.colors[this.dataItem[this.fieldName]];
  }
  @Input() useDefaultColorInOptionList = false;
  @Input() filterSelectOption?: (
    value: string,
    selectOptions?: SelectOption[]
  ) => SelectOption[] | undefined;
  @Input() saveEvent: EventEmitter<any>;

  @Output() onChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onArrowDown = new EventEmitter();

  filteredSelectOptions: SelectOption[] = undefined;
  selectedOptionColor: string;
  isOpen = false;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setFilteredSelectOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let idx =
      this.selectOptions.findIndex(
        (x) => x.value === changes.dataItem?.currentValue?.confidence
      ) + 1;

    if (
      changes.dataItem?.previousValue &&
      changes.dataItem?.currentValue &&
      (changes.dataItem.previousValue.id !==
        changes.dataItem.currentValue?.id ||
        (idx > -1 && idx < this.selectOptions.length))
    ) {
      this.setFilteredSelectOptions();
    }
  }

  setFilteredSelectOptions(value?: any) {
    if (this.filterSelectOption) {
      this.filteredSelectOptions = this.filterSelectOption(
        value ?? this.dataItem[this.fieldName],
        this.selectOptions
      );
    } else {
      this.filteredSelectOptions = this.selectOptions;
    }
  }

  onChangeHandler(event: any) {
    let value = event.target.value;

    this.dataItem[this.fieldName] = value;

    this.setColor(value);

    this.onChange.emit({
      id: this.id,
      fieldName: this.fieldName,
      dataItem: this.dataItem,
      value: value !== "" ? value : null,
    } as OnChangeSelectEmitObj);
  }

  onFocusHandler() {
    // this.setFilteredSelectOptions();

    this.onFocus.emit({
      dataItem: this.dataItem,
      fieldName: this.fieldName,
    } as OnFocusSelectEmitObj);
  }

  onFocusOutHandler() {
    this.toggleFocus(false);

    this.onFocusOut.emit();
  }

  onMouseDownHandler() {
    this.toggleFocus(true);
  }

  onKeyDownHandler(event: KeyboardEvent) {
    this.toggleFocus(true);

    if (
      event.key === ArrowKeys.Up ||
      event.key === ArrowKeys.Right ||
      event.key === ArrowKeys.Down ||
      event.key === ArrowKeys.Left
    ) {
      event.preventDefault();
      this.onArrowDown.emit({
        id: this.id,
        key: event.key,
      } as OnKeyDownEmitObj);
    }
  }

  setColor(team?: string) {
    if (!this.colors) return;

    this.selectedOptionColor = this.colors[team] ?? this.colors.default;
    this._changeDetectorRef.detectChanges();
  }

  toggleFocus(value?: boolean) {
    this.isOpen = value ?? !this.isOpen;
  }
}
