import { DropdownOption } from "src/shared/components/dropdown/dropdownOption";

export interface Filters {
  visibility?: boolean;
  year?: string;
  team?: string[];
  projectName?: string[];
  employeeName?: string[];
  confidence?: string[];
  rolling?: string[];
}

export enum FilterRelatedNames {
  visibility = "visibility",
  year = "year",
  team = "team",
  employeeName = "employeeName",
  projectName = "projectName",
  confidence = "confidence",
  rolling = "rolling",
}
export enum VisibilityOption {
  visible = "Visible",
  invisible = "Invisible",
}

export interface FiltersOptions {
  [FilterRelatedNames.team]: DropdownOption[] | undefined;
  [FilterRelatedNames.employeeName]?: DropdownOption[] | undefined;
  [FilterRelatedNames.projectName]?: DropdownOption[] | undefined;
  [FilterRelatedNames.confidence]?: DropdownOption[] | undefined;
  [FilterRelatedNames.rolling]?: DropdownOption[] | undefined;
}
