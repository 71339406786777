import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterRelatedNames, Filters } from "../models/filters.model";
import { DateTimeService } from "./date-time.service";
import { DropdownOption } from "src/shared/components/dropdown/dropdownOption";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  constructor(private _dateTimeService: DateTimeService) {}

  updateFilterWithTeamYearDependencies(
    filters: Filters,
    form: FormGroup
  ): Filters {
    if (!filters || !form) return;

    const updatedFilters = this.getUpdatedFilters(filters, form);
    const hasYearChanged = filters.year !== updatedFilters.year;
    const hasTeamChanged =
      filters.team?.toString() !== updatedFilters.team?.toString();

    if (hasYearChanged) {
      updatedFilters.team = [];
      form.get(FilterRelatedNames.team).setValue([]);
    }

    if (hasYearChanged || hasTeamChanged) {
      updatedFilters.employeeName = [];
      form.get(FilterRelatedNames.employeeName)?.setValue([]);
    }

    return updatedFilters;
  }

  updateFilter(filters: Filters, form: FormGroup) {
    if (!filters || !form) return;

    return this.getUpdatedFilters(filters, form);
  }

  private getUpdatedFilters(filters: Filters, form: FormGroup) {
    const updatedFilters: Filters = {};

    Object.keys(filters).forEach(
      (key) => (updatedFilters[key] = form.get([key])?.value)
    );

    return updatedFilters;
  }
}
