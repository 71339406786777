import { createAction, props } from "@ngrx/store";
import { NavListItem } from "src/shared/components/nav-list/nav-list-item";

export enum RouterActionTypes {
  NavigateToContracts = "[Router] Navigate To Contracts",
}

export const navigateToContracts = createAction(
  RouterActionTypes.NavigateToContracts,
  props<{ item: NavListItem }>()
);
