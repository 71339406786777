<div
  class="numeric-input-container"
  [style]="{
    'background-color': this.dataItem[this.fieldName]
      ? backgroundColor
      : 'inherit'
  }"
>
  <input
    [id]="id"
    class="input-default"
    [value]="this.dataItem[this.fieldName]"
    [style]="{
      'text-align': alignValue,
      'font-weight': isDirty ? '800' : 'normal'
    }"
    (focus)="focus()"
    (focusout)="focusOut()"
    (input)="change($event)"
    (keydown)="onKeyDownHandler($event)"
    oninput="(validity.valid || (value=value.slice(0, value.length - 1)))"
    [pattern]="inputPattern"
  />
</div>
