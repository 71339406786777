import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from './reducers';
import { IAuthState } from './state';

export interface AuthFeatureState {
  auth: IAuthState;
}

export const reducers: ActionReducerMap<AuthFeatureState> = {
  auth: authReducer,
};
