import { createSelector, createFeatureSelector } from "@ngrx/store";
import { RouterReducerState } from "@ngrx/router-store";
import { RouterStateUrl } from "./router.state";
import * as fromRouter from "@ngrx/router-store";

export const routerSelector =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>("router");

const { selectUrl } = fromRouter.getSelectors(routerSelector);

export const getNavItemFromUrl = selectUrl;
