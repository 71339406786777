<div class="dialog-container">
  <h1 class="title">{{ title }}</h1>
  <ng-content></ng-content>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="cancel-button">Cancel</button>
    <app-button
      [content]="confirmButtonName"
      (mousedown)="onMouseDownHandler()"
      [isDisabled]="disableConfirmButton"
    ></app-button>
  </div>
</div>
