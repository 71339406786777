import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContractedProjectDto } from "../api-clients";
import { OdataBase } from "./base-odata.service";

@Injectable({ providedIn: "root" })
export class ContractedProjectOdata {
  private _entityName = "ContractedProject";

  public constructor(private odata: OdataBase<ContractedProjectDto>) {}

  public getAllActiveContractedProject(): Observable<ContractedProjectDto[]> {
    return this.odata.GetAll(this._entityName);
  }
}
