import { AzureUser } from "src/core/models/azure-user.model";

export const FEATURE_NAME: string = 'auth-feature';

export interface IAuthState {
  isAuthenticated: boolean;
  userPhotoUrl: string;
  user: AzureUser;
}

export const initialAuthState: IAuthState = {
  isAuthenticated: false,
  user: null,
  userPhotoUrl: null
};
