import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { contractsReducer } from "./contracts.reducer";
import { contractsStoreName } from "./contracts.state";
import { EffectsModule } from "@ngrx/effects";
import { ContractsEffects } from "./contracts.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(contractsStoreName, contractsReducer),
    EffectsModule.forFeature([ContractsEffects]),
  ],
})
export class ContractsStoreModule {}
