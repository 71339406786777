import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { EMPLOYEES_PATH } from "src/app/contracts/employees-contracts/employees-contracts.component";
import { PROJECTS_PATH } from "src/app/contracts/projects-contracts/projects-contracts.component";
import { ROLLING_PATH } from "src/app/contracts/rolling-contracts/rolling-contracts.component";
import { AppState } from "src/core/app.state";
import { navigateToContracts } from "src/core/stores/router-store/router.actions";
import { NavListItem } from "src/shared/components/nav-list/nav-list-item";
import { getNavListItemFromUrl } from "../../stores/layout-store/layout.selectors";
import { tap } from "rxjs/operators";
import { CardsNames } from "src/app/app.model";

@Component({
  selector: "app-menu-sidenav",
  templateUrl: "./menu-sidenav.component.html",
  styleUrls: ["./menu-sidenav.component.scss"],
})
export class MenuSidenavComponent {
  constructor(private _store: Store<AppState>) {}

  navListItems: NavListItem[] = [
    {
      name: CardsNames.employeeContracts,
      isSelected: false,
      iconName: "employees-contracts",
      navigationPath: EMPLOYEES_PATH,
    },
    {
      name: CardsNames.projectContracts,
      isSelected: false,
      iconName: "projects-contracts",
      navigationPath: PROJECTS_PATH,
    },
    {
      name: CardsNames.rollingContracts,
      isSelected: false,
      iconName: "rolling-contracts",
      navigationPath: ROLLING_PATH,
    },
  ];

  selectedNavListItemName$ = this._store.select(getNavListItemFromUrl).pipe(
    tap((param) => {
      let updatedItems = [];
      this.navListItems.forEach((item) =>
        updatedItems.push(
          Object.assign(
            {},
            { ...item, isSelected: `/${item.navigationPath}` === param }
          )
        )
      );
      this.navListItems = updatedItems;
    })
  );

  navigateTo(navListItem: NavListItem): void {
    this._store.dispatch(navigateToContracts({ item: navListItem }));
  }
}
