import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Location } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    Configuration,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store';
import { FEATURE_NAME, IAuthState } from './store/state';
import { AuthEffects } from './store/effects';
import { AuthService } from './auth.service';
import { authRedirectRoutes } from './auth.routing';
import { environment } from 'src/environments/environment';
import { Store, StoreModule } from '@ngrx/store';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export function provideAuthService(injector: Injector) {
    return new AuthService(
        injector.get<MsalGuardConfiguration>(MSAL_GUARD_CONFIG),
        injector.get(MsalBroadcastService),
        injector.get(MsalService),
        injector.get<Store<IAuthState>>(Store),
    );
}

export function provideInterceptor(injector: Injector) {
    return new MsalInterceptor(
        injector.get<MsalInterceptorConfiguration>(MSAL_INTERCEPTOR_CONFIG),
        injector.get(MsalService),
        injector.get(Location),
        window.document,
    );
}

export function MSALInstanceFactory(): IPublicClientApplication {
    const redirectUrl =
        environment.msalModule.redirectUri[0] == '/'
            ? window.location.origin + environment.msalModule.redirectUri
            : environment.msalModule.redirectUri;

    return new PublicClientApplication({
        auth: {
            clientId: environment.msalModule.clientID,
            authority: environment.msalModule.authority,
            redirectUri: redirectUrl,
            knownAuthorities: [environment.msalModule.authority.split('/')[2]],
            postLogoutRedirectUri: `/`,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
        },
    });
}

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.msalModule.clientID,
        authority: environment.msalModule.authority,
        redirectUri: environment.msalModule.redirectUri,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage',
    },
    system: {
        loadFrameTimeout: 60000,
    }
};


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.baseUrl, [environment.msalModule.appScope]);
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/photo/$value', ['user.read']);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: protectedResourceMap

    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [environment.msalModule.appScope],
        },
    };
}

@NgModule({
    imports: [
        HttpClientModule,
        MsalModule,
        StoreModule.forFeature(FEATURE_NAME, reducers),
        EffectsModule.forFeature([AuthEffects]),
        RouterModule.forChild(authRedirectRoutes),
    ],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: provideInterceptor,
            deps: [Injector],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            deps: [AuthService],
            useFactory: (authService: AuthService): any => (): void => {
                authService.startListening().subscribe();
            },
            multi: true
        },
        MsalGuard,
        MsalService,
        MsalBroadcastService,
        AuthService,
        MsalInterceptor
    ],
})
export class AuthModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error(
                'AuthModule has already been loaded. You should only import AuthModule modules in the AppModule only.',
            );
        }
    }
}
