import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { NavListItem } from "./nav-list-item";

@Component({
  selector: "app-nav-list",
  templateUrl: "./nav-list.component.html",
  styleUrls: ["./nav-list.component.scss"],
})
export class NavListComponent {
  @Input() color: ThemePalette = "primary";
  @Input() items: NavListItem[];

  @Output() onClick = new EventEmitter<NavListItem>();

  itemClicked(item: NavListItem): void {
    if (item?.navigationPath) {
      this.onClick.emit(item);
    }
  }
}
