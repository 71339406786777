export const environment = {
  production: true,
  baseUrl: "https://pmo-server.arpideas.pl",

  msalModule: {
    clientID: "92eb78dc-a5ce-46f7-9afd-d59a48653799",
    authority: "https://login.microsoftonline.com/arpideas.pl/",
    redirectUri: "https://pmo.arpideas.pl",
    appScope: "api://92eb78dc-a5ce-46f7-9afd-d59a48653799/user_impersonation", // Scope udostnione przez Azure podczas rejestracji aplikacji'
  },

  groups: {
    canRead: ["Team PMO"],
    canWrite: ["Team PMO"],
  },
};
