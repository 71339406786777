import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ContractedEmployeeGridField } from "src/app/contracts/employees-contracts/employees-contracts.model";
import { ContractedProjectGridField } from "src/app/contracts/projects-contracts/projects-contracts.model";
import { ContractedRollingGridField } from "src/app/contracts/rolling-contracts/rolling-contracts.model";
import { DropdownOption } from "src/shared/components/dropdown/dropdownOption";
import { GridDefinition } from "src/shared/components/grid/grid-definition";
import { CalendarState, calendarStoreName } from "./calendar.state";

export const selectCalendars =
  createFeatureSelector<CalendarState>(calendarStoreName);

export const getCalendars = createSelector(
  selectCalendars,
  (state: CalendarState) => state.calendars
);

export const getYearsRange = createSelector(
  selectCalendars,
  (state: CalendarState) => state.yearsRange.map((year) => `${year}`)
);

export const getCurrentWeekNumber = createSelector(
  selectCalendars,
  (state: CalendarState) => state.currentWeekNumber
);

export const getCalendarGridForContractedEmployees = (year: string) => createSelector(
  selectCalendars,
  (state: CalendarState) => {
    let grid = new GridDefinition();

    grid.addTextColumn({
      field: ContractedEmployeeGridField.employeeName,
      title: "Employee",
      widthPx: 140,
      justifyTo: "left",
      calculateWidthBasedOnValue: true,
      isMain: true,
    });
    grid.addTextColumn({
      field: ContractedEmployeeGridField.employeeType,
      title: "Type",
      justifyTo: "center",
    });
    grid.addTextColumn({
      field: ContractedEmployeeGridField.year,
      title: "Year",
      widthPx: 50,
      justifyTo: "center",
    });
    grid.addBooleanColumn({
      field: ContractedEmployeeGridField.isVisible,
      title: "V",
      widthPx: 30,
    });
    grid.addWeekColumns(state.calendars, year);
    grid.setLockedColumns(4);
    return grid;
  }
);

export const getCalendarGridForContractedProjects = (year: string) => createSelector(
  selectCalendars,
  (state: CalendarState) => {
    let grid = new GridDefinition();
    grid.addTextColumn({
      field: ContractedProjectGridField.projectName,
      title: "Project",
      widthPx: 120,
      justifyTo: "left",
      calculateWidthBasedOnValue: true,
      isMain: true,
    });
    grid.addTextColumn({
      field: ContractedProjectGridField.year,
      title: "Year",
      widthPx: 50,
      justifyTo: "center",
    });
    grid.addBooleanColumn({
      field: ContractedProjectGridField.isVisible,
      title: "V",
      widthPx: 30,
    });
    grid.addWeekColumns(state.calendars, year);
    grid.setLockedColumns(3);

    return grid;
  }
);

export const getCalendarGridForRollingContracts = (year: string) => createSelector(
  selectCalendars,
  (state: CalendarState) => {
    let grid = new GridDefinition();
    grid.addTextColumn({
      field: ContractedEmployeeGridField.summary,
      title: "Σ",
      justifyTo: "right",
      justifyHeaderTo: "right",
      calculateWidthBasedOnValue: true,
    });
    grid.addTextColumn({
      field: ContractedRollingGridField.opportunityName,
      title: "Opportunity",
      widthPx: 170,
      isEditable: true,
    });
    grid.addTextColumn({
      field: ContractedRollingGridField.projectName,
      title: "Project",
      widthPx: 120,
      isEditable: true,
    });

    grid.addEnumColumn({
      field: ContractedRollingGridField.teamSymbol,
      title: "Team",
      widthPx: 45,
      justifyTo: "center",
      isEditable: true,
    });
    grid.addTextColumn({
      field: ContractedRollingGridField.employeeName,
      title: "Employee",
      widthPx: 80,
      isEditable: true,
    });
    grid.addBooleanColumn({
      field: ContractedRollingGridField.isVisible,
      title: "V",
      widthPx: 30,
    });
    grid.addEnumColumn({
      field: ContractedRollingGridField.confidenceSymbol,
      title: "P",
      widthPx: 30,
      justifyTo: "center",
      setFullHeight: true,
      optionSetKey: "confidence",
    }); //Probability
    grid.addTextColumn({
      field: ContractedRollingGridField.year,
      title: "Year",
      widthPx: 50,
      justifyTo: "center",
    });
    grid.addWeekColumns(state.calendars, year, "numeric", true, 57);
    grid.setLockedColumns(8);

    return grid;
  }
);

export const getYearFilterOptions = createSelector(
  getYearsRange,
  (years: string[]) =>
    years
      .sort()
      .map(
        (year) =>
          ({ name: year, value: year, isDisabled: false } as DropdownOption)
      )
);
