import { RouterReducerState } from "@ngrx/router-store";
import { MetaReducer } from "@ngrx/store";
import { storeFreeze } from "ngrx-store-freeze";
import { environment } from "../environments/environment";
import { IAuthState, initialAuthState } from "./auth/store/state";
import {
  initialLayoutState,
  LayoutState,
} from "./stores/layout-store/layout.state";
import { RouterStateUrl } from "./stores/router-store/router.state";
import {
  ContractsState,
  initialContractsState,
} from "./stores/contracts-store/contracts.state";
import {
  CalendarState,
  initialCalendarState,
} from "./stores/calendar-store/calendar.state";

export interface AppState {
  auth: IAuthState;
  layout: LayoutState;
  contracts: ContractsState;
  calendars: CalendarState;
  router?: RouterReducerState<RouterStateUrl>;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  layout: initialLayoutState,
  contracts: initialContractsState,
  calendars: initialCalendarState,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze]
  : [];
