<app-dialog-container
  title="Multi-Fill"
  confirmButtonName="Fill"
  [disableConfirmButton]="disableFillButton"
  (onMouseDownEvent)="confirm()"
>
  <div class="dialog-rolling-content" *ngIf="weeks">
    <app-switch-box
      leftOption="Overall distribution"
      rightOption="Per Week"
      [control]="formGroup.get(formFieldNames.perWeek)"
    ></app-switch-box>
    <div class="dialog-field-container">
      <app-autocomplete
        placeholder="The number of week"
        label="Initial Week"
        type="number"
        [options]="[]"
        [control]="formGroup.get(formFieldNames.initialWeek)"
        [required]="true"
      ></app-autocomplete>
      <app-autocomplete
        placeholder="The number of week"
        label="Final Week"
        type="number"
        [options]="[]"
        [control]="formGroup.get(formFieldNames.finalWeek)"
      ></app-autocomplete>
      <app-autocomplete
        placeholder="Enter the requested hours"
        label="Hours"
        type="number"
        [options]="[]"
        [control]="formGroup.get(formFieldNames.hours)"
        [required]="true"
      ></app-autocomplete>
    </div>
  </div>
</app-dialog-container>
