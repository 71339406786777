import { createFeatureSelector, createSelector } from "@ngrx/store";
import { confidaneColors, teamColors } from "src/app/app.model";
import { RollingDialogFieldNames } from "src/app/contracts/rolling-contracts/new-rolling-contract-dialog/new-rolling-contract-dialog/new-rolling-contract-dialog-config.model";
import {
  FilterRelatedNames,
  Filters,
  VisibilityOption,
} from "src/core/models/filters.model";
import { SelectOption } from "src/core/models/select-option";
import {
  Confidence,
  ContractedEmployeeDto,
  ContractedProjectDto,
  Project,
  RollingContractDto,
  TeamDto,
} from "src/core/services/api/api-clients";
import { DropdownOption } from "src/shared/components/dropdown/dropdownOption";
import {
  getCurrentWeekNumber,
  getYearFilterOptions,
} from "../calendar-store/calendar.selectors";
import { ContractsState, contractsStoreName } from "./contracts.state";
import { optionSetKeys } from "src/shared/components/grid/grid-definition";

export const selectContracts =
  createFeatureSelector<ContractsState>(contractsStoreName);

export const getContractedEmployees = createSelector(
  selectContracts,
  (state: ContractsState) => {
    let array: ContractedEmployeeDto[] = [];
    Object.keys(state.contractedEmployees).forEach((key) => {
      array = [
        ...array,
        {
          ...state.contractedEmployees[parseInt(key)],
        } as ContractedEmployeeDto,
      ];
    });
    return array;
  }
);

export const getContractedProjects = createSelector(
  selectContracts,
  (state: ContractsState) => {
    let array: ContractedProjectDto[] = [];
    Object.keys(state.contractedProjects).forEach((key) => {
      array = [
        ...array,
        {
          ...state.contractedProjects[parseInt(key)],
        } as ContractedProjectDto,
      ];
    });
    return array;
  }
);

export const getRollingContracts = createSelector(
  selectContracts,
  (state: ContractsState) => {
    let array: RollingContractDto[] = [];
    Object.keys(state.rollingContracts).forEach((key) => {
      array = [
        ...array,
        { ...state.rollingContracts[parseInt(key)] } as RollingContractDto,
      ];
    });
    return array;
  }
);

export const getActiveContractedEmployees = createSelector(
  getContractedEmployees,
  () => new Date().getFullYear(),
  (employees: ContractedEmployeeDto[], currentYear: number) =>
    employees.filter(
      (employee) =>
        employee.isVisible &&
        (employee.year === `${currentYear}` ||
          employee.year === `${currentYear + 1}`)
    )
);

export const getActiveContractedProjects = createSelector(
  getContractedProjects,
  () => new Date().getFullYear(),
  (projects: ContractedProjectDto[], currentYear: number) =>
    projects.filter(
      (project) =>
        project.isVisible &&
        (project.year === `${currentYear}` ||
          project.year === `${currentYear + 1}`)
    )
);

export const getActiveRollingContracts = createSelector(
  getRollingContracts,
  (contracts: RollingContractDto[]) =>
    contracts.filter((contract) => contract.isVisible)
);

export const getTeams = createSelector(
  selectContracts,
  (state: ContractsState) => state.teams
);

export const getVisibilityOptions = createSelector(() => [
  {
    id: 1,
    value: true,
    name: VisibilityOption.visible,
    isDisabled: false,
  } as DropdownOption,
  {
    id: 2,
    value: false,
    name: VisibilityOption.invisible,
    isDisabled: false,
  } as DropdownOption,
]);

export const getTeamsAsOptionSet = createSelector(
  selectContracts,
  (state: ContractsState) =>
    state.teams.map(
      (team: TeamDto) =>
        ({
          value: team.fullSymbol,
          displayText: team.fullSymbol,
          shortText: team.shortSymbol,
          color: teamColors[team.fullSymbol] ?? teamColors.default,
        } as SelectOption)
    )
);

export const getConfidenceAsOptionSet = createSelector(
  selectContracts,
  (state: ContractsState) =>
    state.confidences.map(
      (c: Confidence) =>
        ({
          value: c.symbol,
          displayText: `${c.symbol} (${c.name})`,
          shortText: c.symbol,
          color: confidaneColors[c.symbol],
        } as SelectOption)
    )
);

export const getTeamNames = createSelector(
  getTeamsAsOptionSet,
  (teams: SelectOption[]) => {
    let teamNames = {};
    teams.forEach((team) => {
      teamNames[team.displayText] = team.displayText;
    });
    return teamNames;
  }
);

export const getEmployees = createSelector(
  selectContracts,
  (state: ContractsState) => {
    return Object.values(state.employees);
  }
);

export const getProjects = createSelector(
  selectContracts,
  (state: ContractsState) => {
    let array: Project[] = [];
    Object.keys(state.projects).forEach((key) => {
      array = [
        ...array,
        {
          ...state.projects[key],
        } as Project,
      ];
    });
    return array;
  }
);

export const getFilteredContractedEmployees = (filters: Filters) =>
  createSelector(
    getContractedEmployees,
    getCurrentWeekNumber,
    (state: ContractedEmployeeDto[], currentWeekNumber: number) => {
      let weekName: string;

      if (filters.team?.length)
        weekName = `w${currentWeekNumber < 10 ? "0" : ""}${currentWeekNumber}`;

      return state
        .filter(
          (x) =>
            (filters.visibility === x.isVisible ||
              filters.visibility === undefined) &&
            (!filters.year || filters.year === x.year) &&
            (!filters.employeeName?.length ||
              filters.employeeName.includes(x.employeeName)) &&
            (!filters.team?.length || filters.team.includes(x[weekName]))
        )
        .sort((a, b) => a.employeeName.localeCompare(b.employeeName));
    }
  );

export const getFilteredContractedProjects = (filters: Filters) =>
  createSelector(
    getContractedProjects,
    getCurrentWeekNumber,
    (state: ContractedProjectDto[], currentWeekNumber: number) => {
      let weekName: string;

      if (filters.team?.length)
        weekName = `w${currentWeekNumber < 10 ? "0" : ""}${currentWeekNumber}`;

      return state
        .filter(
          (x) =>
            (filters.visibility === x.isVisible ||
              filters.visibility === undefined) &&
            (!filters.year || filters.year === x.year) &&
            (!filters.projectName?.length ||
              filters.projectName.includes(x.projectName)) &&
            (!filters.team?.length || filters.team.includes(x[weekName]))
        )
        .sort((a, b) => a.projectName.localeCompare(b.projectName));
    }
  );

export const getFilteredRollingContracts = (filters: Filters) =>
  createSelector(
    getTeamNames,
    getRollingContracts,
    (teams: Record<string, string>, state: RollingContractDto[]) =>
      state
        .filter(
          (x) =>
            (filters.visibility === x.isVisible ||
              filters.visibility === undefined) &&
            (!filters.year || filters.year === x.year) &&
            (!filters.projectName?.length ||
              filters.projectName.includes(x.projectName)) &&
            (!filters.team?.length ||
              filters.team.includes(teams[x.teamSymbol])) &&
            (!filters.confidence?.length ||
              filters.confidence.includes(x.confidenceSymbol)) &&
            (!filters.rolling?.length ||
              filters.rolling.includes(x.opportunityName))
        )
        .sort((a, b) => {
          // Compare by Team
          const teamComparison = a.teamSymbol.localeCompare(b.teamSymbol);
          if (teamComparison !== 0) return teamComparison;

          // If Team properties are equal, compare by P
          const confidenceSymbolComparison = a.confidenceSymbol.localeCompare(
            b.confidenceSymbol
          );
          if (confidenceSymbolComparison !== 0)
            return confidenceSymbolComparison;

          // If Team and P properties are equal, compare by EmployeeName
          const employeeNameComparison = a.employeeName.localeCompare(
            b.employeeName
          );
          if (employeeNameComparison !== 0) return employeeNameComparison;

          // If Team, P, and EmployeeName properties are equal, compare by ProjectName
          const projectNameComparison = a.projectName.localeCompare(
            b.projectName
          );
          if (projectNameComparison !== 0) return projectNameComparison;

          // If Team, P, EmployeeName, and ProjectName properties are equal, compare by Opportunity
          return a.opportunityName.localeCompare(b.opportunityName);
        })
  );

export const getTeamFilterOptions = createSelector(
  getTeams,
  (teams: TeamDto[]) =>
    teams
      .map(
        (team) =>
          ({
            id: team.id,
            name: team.fullSymbol,
            value: team.fullSymbol,
            isDisabled: false,
          } as DropdownOption)
      )
      .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0))
);

export const getContractedEmployeeFilterOptions = (
  displayedEmployees: string[]
) =>
  createSelector(
    getContractedEmployees,
    (employees: ContractedEmployeeDto[]) => {
      const uniqueEmployees = new Set<string>();
      const options: DropdownOption[] = [];

      employees.forEach((e) => {
        if (
          !uniqueEmployees.has(e.employeeName) &&
          displayedEmployees.includes(e.employeeName)
        ) {
          uniqueEmployees.add(e.employeeName);
          options.push({
            id: e.id,
            name: e.employeeName,
            value: e.employeeName,
            isDisabled: false,
          } as DropdownOption);
        }
      });

      return options.sort((a, b) => a.name.localeCompare(b.name));
    }
  );

export const getContractedProjectFilterOptions = (
  displayedProjects: string[]
) =>
  createSelector(getContractedProjects, (projects: ContractedProjectDto[]) => {
    const uniqueProjects = new Set<string>();
    const options: DropdownOption[] = [];

    projects.forEach((p) => {
      if (
        !uniqueProjects.has(p.projectName) &&
        displayedProjects?.includes(p.projectName)
      ) {
        uniqueProjects.add(p.projectName);
        options.push({
          id: p.id,
          name: p.projectName,
          value: p.projectName,
          isDisabled: false,
        } as DropdownOption);
      }
    });

    return options.sort((a, b) => a.name.localeCompare(b.name));
  });

export const getConfidenceFilterOptions = createSelector(
  selectContracts,
  (state) =>
    state.confidences
      .map(
        (confidence) =>
          ({
            id: confidence.id,
            name: confidence.symbol,
            value: confidence.symbol,
            isDisabled: false,
          } as DropdownOption)
      )
      .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0))
);

export const getOpportunityFilterOptions = (displayedOpportunities: string[]) =>
  createSelector(getRollingContracts, (contracts: RollingContractDto[]) => {
    const uniqueOpportunities = new Set<string>();
    const options: DropdownOption[] = [];

    contracts.forEach((contract) => {
      if (
        !uniqueOpportunities.has(contract.opportunityName) &&
        displayedOpportunities.includes(contract.opportunityName)
      ) {
        uniqueOpportunities.add(contract.opportunityName);
        options.push({
          id: contract.id,
          name: contract.opportunityName,
          value: contract.opportunityName,
          isDisabled: false,
        } as DropdownOption);
      }
    });

    return options.sort((a, b) => a.name.localeCompare(b.name));
  });

export const getActiveContractedEmployeeFilterOptions = createSelector(
  getActiveContractedEmployees,
  (employees: ContractedEmployeeDto[]) =>
    employees
      .map((employee) => {
        const name = employee.employeeName;
        return {
          id: employee.id,
          name: name,
          value: name,
          isDisabled: false,
        } as DropdownOption;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
);

export const getActiveContractedProjectFilterOptions = createSelector(
  getActiveContractedProjects,
  (projects: ContractedProjectDto[]) =>
    projects
      .map(
        (projects) =>
          ({
            id: projects.id,
            name: projects.projectName,
            value: projects.projectName,
            isDisabled: false,
          } as DropdownOption)
      )
      .sort((a, b) => a.name.localeCompare(b.name))
);

export const getProjectFilterOption = createSelector(
  getProjects,
  (projects: Project[]) =>
    projects
      .map(
        (projects) =>
          ({
            id: projects.id,
            name: projects.name,
            value: projects.name,
            isDisabled: false,
          } as DropdownOption)
      )
      .sort((a, b) => a.name.localeCompare(b.name))
);

export const getContractedEmployeeViewFilterOptions = (
  displayedEmployees: string[]
) =>
  createSelector(
    getVisibilityOptions,
    getContractedEmployeeFilterOptions(displayedEmployees),
    getTeamFilterOptions,
    getYearFilterOptions,
    (
      visibilityOption: DropdownOption[],
      employees: DropdownOption[],
      teams: DropdownOption[],
      years: DropdownOption[]
    ) => ({
      [FilterRelatedNames.visibility]: visibilityOption,
      [FilterRelatedNames.employeeName]: employees,
      [FilterRelatedNames.year]: years,
      [FilterRelatedNames.team]: teams,
    })
  );

export const getContractedProjectViewFilterOptions = (
  displayedProjects: string[]
) =>
  createSelector(
    getVisibilityOptions,
    getContractedProjectFilterOptions(displayedProjects),
    getTeamFilterOptions,
    getYearFilterOptions,
    (
      visibilityOption: DropdownOption[],
      projects: DropdownOption[],
      teams: DropdownOption[],
      years: DropdownOption[]
    ) => ({
      [FilterRelatedNames.visibility]: visibilityOption,
      [FilterRelatedNames.projectName]: projects,
      [FilterRelatedNames.year]: years,
      [FilterRelatedNames.team]: teams,
    })
  );

export const getRollingContractsViewFilterOptions = (
  displayedProjects: string[],
  displayedOpportunities: string[]
) =>
  createSelector(
    getVisibilityOptions,
    getContractedProjectFilterOptions(displayedProjects),
    getTeamFilterOptions,
    getYearFilterOptions,
    getConfidenceFilterOptions,
    getOpportunityFilterOptions(displayedOpportunities),
    (
      visibilityOption: DropdownOption[],
      projects: DropdownOption[],
      teams: DropdownOption[],
      years: DropdownOption[],
      confidences: DropdownOption[],
      opportunities: DropdownOption[]
    ) => ({
      [FilterRelatedNames.visibility]: visibilityOption,
      [FilterRelatedNames.projectName]: projects,
      [FilterRelatedNames.year]: years,
      [FilterRelatedNames.team]: teams,
      [FilterRelatedNames.confidence]: confidences,
      [FilterRelatedNames.rolling]: opportunities,
    })
  );

export const getNewRollingContractDialogOptions = createSelector(
  getTeamFilterOptions,
  getProjectFilterOption,
  getActiveContractedEmployeeFilterOptions,
  getConfidenceFilterOptions,
  (
    teams: DropdownOption[],
    contractedProjects: DropdownOption[],
    contractedEmployee: DropdownOption[],
    confidences: DropdownOption[]
  ) => ({
    [RollingDialogFieldNames.team]: teams,
    [RollingDialogFieldNames.contractedProject]: contractedProjects,
    [RollingDialogFieldNames.contractedEmployee]: contractedEmployee,
    [RollingDialogFieldNames.confidence]: confidences,
  })
);

export const getRollingAutoCompleteOptions = createSelector(
  getActiveContractedProjects,
  getTeams,
  getActiveContractedEmployees,
  (projects, teams, employees) => ({
    projectName: projects.map((project) => project.projectName).sort(),
    employeeName: employees.map((employee) => employee.employeeName).sort(),
    teamSymbol: teams.map((team) => team.fullSymbol).sort(),
  })
);

export const getRollingContractOptions = createSelector(
  getConfidenceAsOptionSet,
  getTeamsAsOptionSet,
  (confidence, teams) => ({
    [optionSetKeys.confidence]: confidence,
    [optionSetKeys.teams]: teams,
  })
);
