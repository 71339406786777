<mat-form-field
  [appearance]="outline ? 'outline' : ''"
  class="dropdown-container"
>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    #selectElem
    [formControl]="control"
    (selectionChange)="selectionChanged()"
    (openedChange)="multipleChanged()"
    [multiple]="isMultiselect"
    [required]="required"
  >
    <mat-option
      *ngIf="!isMultiselect && !required"
      [value]="undefinded"
    ></mat-option>
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
