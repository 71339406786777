<app-header
  class="header"
  [title]="cardsNames.employeeContracts"
  *ngIf="filtersOptions$ | async as options"
>
  <div dropdowns class="filters-container">
    <app-dropdown
      placeholder="Visibility"
      [options]="options[optionNames.visibility]"
      [control]="form.get(optionNames.visibility)"
      (onChange)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Year"
      [options]="options[optionNames.year]"
      [control]="form.get(optionNames.year)"
      (onChange)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Team"
      [options]="options[optionNames.team]"
      [control]="form.get(optionNames.team)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
    <app-dropdown
      placeholder="Employee"
      [options]="options[optionNames.employeeName]"
      [control]="form.get(optionNames.employeeName)"
      [isMultiselect]="true"
      (onClose)="updateFilters()"
    ></app-dropdown>
  </div>
</app-header>
<div class="content-box">
  <app-grid
    [gridDefinition]="grid$ | async"
    [data]="data$ | async"
    [optionSet]="teams$ | async"
    [backgroundColors]="teamsBackgroundColor"
    [isRollingContracts]="false"
    (onSaveData)="saveUpdates($event)"
    [(editedDataItem)]="editedDataItem"
    [(selectedDataItem)]="selectedDataItem"
    [(lastClickedFieldName)]="lastClickedFieldName"
    [hiddenColumns]="hiddenColumns"
    [externalDataChangeEvent]="fillTillEndEvent"
  >
    <app-button
      actions
      content="Fill until end of year"
      (mousedown)="fillTillEnd()"
      [isDisabled]="lastClickedFieldName === null"
    ></app-button>
  </app-grid>
</div>
