import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class OdataBase<T> {
  private _baseUrl: string;

  constructor(private http: HttpClient) {
    this._baseUrl = environment.baseUrl;
  }

  public GetAll(entityName: string, query?: string): Observable<T[]> {
    return this.http.get<T[]>(
      `${this._baseUrl}/odata/${entityName}/${
        query !== null ? `?${query}` : ""
      }`
    );
  }

  public getQuery(params: string[]) {
    let query = "";

    for (let i = 0; i < params.length; i++) {
      query += params[i];

      if (i + 1 < params.length) {
        query += "&";
      }
    }

    return query;
  }
}
