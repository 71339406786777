import { createReducer, on } from "@ngrx/store";
import { initialCalendarState } from "./calendar.state";
import { loadCalendarsFailure, loadCalendarsSuccess } from "./calendar.actions";

export const calendarReducer = createReducer(
  initialCalendarState,
  on(loadCalendarsSuccess, (state, { calendarDto }) => ({
    ...state,
    calendars: calendarDto.weeks,
    currentWeekNumber: calendarDto.currentWeekNumber,
    yearsRange: calendarDto.yearsRange,
    error: null,
  })),
  on(loadCalendarsFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
