import { SelectOption } from "src/core/models/select-option";
import { WeekDto } from "src/core/services/api/api-clients";

export type ColumnType =
  | "text"
  | "numeric"
  | "boolean"
  | "enum"
  | "autonumeration";

export const optionSetKeys = {
  teams: "teams",
  confidence: "confidence",
};

export type JustifyValue = "right" | "center" | "left";

export class Column {
  field: string;
  title: string;
  type: ColumnType;
  filterable: boolean = true;
  sortable: boolean = true;
  isMain: boolean = false;
  enumValues?: SelectOption[];
  width?: string;
  optionSetKey?: string;
  onClick: (value: any) => void;
  format: string;
  isEditable?: boolean;
  isLocked: boolean;
  weekId?: string;
  startsAt?: Date;
  justifyHeaderTo?: JustifyValue;
  justifyTo?: JustifyValue;
  calculateWidthBasedOnValue?: boolean;
  setFullHeight?: boolean;
}

export class GridDefinition {
  columns: Column[] = [];
  editableCellConfig: {
    [key: string]: any;
  };

  rowClassSelector?: (value: any) => any;

  addTextColumn(obj: {
    field: string;
    title: string;
    isEditable?: boolean;
    widthPx?: number;
    justifyHeaderTo?: JustifyValue;
    justifyTo?: JustifyValue;
    calculateWidthBasedOnValue?: boolean;
    isMain?: boolean;
  }) {
    let {
      field,
      title,
      isEditable,
      widthPx,
      justifyHeaderTo,
      justifyTo,
      calculateWidthBasedOnValue,
      isMain,
    } = obj;

    return this.addColumn({
      field,
      title,
      type: "text",
      isEditable,
      width: widthPx ? `${widthPx}px` : undefined,
      justifyHeaderTo,
      justifyTo,
      calculateWidthBasedOnValue,
      isMain: !!isMain,
    } as Column);
  }

  addBooleanColumn(obj: {
    field: string;
    title: string;
    widthPx?: number;
    justifyTo?: JustifyValue;
  }) {
    let { field, title, widthPx, justifyTo } = obj;

    return this.addColumn({
      field,
      title,
      type: "boolean",
      isEditable: false,
      width: widthPx ? `${widthPx}px` : undefined,
      justifyTo,
    } as Column);
  }

  addEnumColumn(obj: {
    field: string;
    title: string;
    isEditable?: boolean;
    widthPx?: number;
    justifyTo: JustifyValue;
    setFullHeight?: boolean;
    optionSetKey?: string | undefined;
  }) {
    let {
      field,
      title,
      isEditable,
      widthPx,
      justifyTo,
      setFullHeight,
      optionSetKey,
    } = obj;
    let column = this.addColumn({
      field,
      title,
      type: "enum",
      isEditable,
      width: widthPx ? `${widthPx}px` : undefined,
      justifyTo,
      setFullHeight,
      optionSetKey: optionSetKey ?? optionSetKeys.teams,
    } as Column);

    return column;
  }

  addAutonumerationColumn(title: string) {
    return this.addColumn({
      field: title,
      title,
      type: "autonumeration",
      isEditable: false,
      width: `40px`,
      justifyTo: "center",
    } as Column);
  }

  addWeekColumns(
    calendar: WeekDto[],
    year?: string,
    type: ColumnType = "enum",
    isEditable: boolean = false,
    width: number = 50,
    optionSetKey?: string
  ) {

    calendar
      .filter(c => c.dateStartYear.toString().includes(year ?? ''))
      .forEach((week, idx) => {
        if(idx < 52){
          this.addColumn({
            field: week.name,
            title: week.name,
          type,
          isEditable: isEditable ?? false,
          width: `${width}px`,
          weekId: week.id,
          startsAt: year ? week.startsAt : '',
          justifyTo: "center",
          optionSetKey: optionSetKey ?? type === "enum" ? "teams" : null,
        } as Column);
      }
    });

    return this.columns;
  }

  addNumericColumn(obj: {
    field: string;
    title: string;
    isEditable?: boolean;
    widthPx?: number;
    justifyTo?: JustifyValue;
    calculateWidthBasedOnValue?: boolean;
  }) {
    let {
      field,
      title,
      isEditable,
      widthPx,
      justifyTo,
      calculateWidthBasedOnValue,
    } = obj;

    return this.addColumn({
      field,
      title,
      type: "numeric",
      isEditable,
      width: widthPx ? `${widthPx}px` : "60px",
      justifyTo,
      calculateWidthBasedOnValue,
    } as Column);
  }

  setLockedColumns(value: number) {
    if (value < this.columns.length - 2) {
      for (let i = 0; i < value; i++) {
        this.columns[i].isLocked = true;
      }
    }
  }

  private addColumn(col: Column) {
    col.width = col.width ?? `75px`;
    col.isLocked = false;
    col.justifyHeaderTo = col.justifyHeaderTo ?? "center";
    col.justifyTo = col.justifyTo ?? "left";
    col.calculateWidthBasedOnValue = col.calculateWidthBasedOnValue ?? false;
    col.setFullHeight = col.setFullHeight ?? false;
    this.columns.push(col);
    return col;
  }
}
