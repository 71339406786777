import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { skipWhile, takeUntil } from "rxjs/operators";
import { AppState } from "src/core/app.state";
import { SubscribedContainer } from "src/core/base/subscribed.container";
import {
  CreateRollingContractDto,
  Employee,
  Project,
} from "src/core/services/api/api-clients";
import {
  getEmployees,
  getNewRollingContractDialogOptions,
  getProjects,
} from "src/core/stores/contracts-store/contracts.selectors";
import { DropdownOption } from "src/shared/components/dropdown/dropdownOption";
import {
  NewRollingContractDialogConfig,
  RollingDialogFieldNames,
} from "./new-rolling-contract-dialog-config.model";
@Component({
  selector: "app-new-rolling-contract-dialog",
  templateUrl: "./new-rolling-contract-dialog.component.html",
  styleUrls: ["./new-rolling-contract-dialog.component.scss"],
})
export class NewRollingContractDialogComponent
  extends SubscribedContainer
  implements OnInit
{
  formNames = RollingDialogFieldNames;
  formGroup: FormGroup;
  disableCreateButton = true;

  formOptions = undefined;
  employees: Employee[] = [];
  projects: Project[] = [];

  yearOptions: DropdownOption[];

  constructor(
    private _dialogRef: MatDialogRef<NewRollingContractDialogComponent>,
    private _store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) private data: NewRollingContractDialogConfig
  ) {
    super();
  }

  ngOnInit(): void {
    this._store
      .select(getNewRollingContractDialogOptions)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((formOptions) => (this.formOptions = formOptions));

    this._store
      .select(getEmployees)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((employees: Employee[]) => (this.employees = employees));

    this._store
      .select(getProjects)
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile((options) => !options)
      )
      .subscribe((projects: Project[]) => (this.projects = projects));

    this.setYearOptions();
    this.setFormGroup();
    this.subscribeToFormGroupStatusChanges();
  }

  confirm(): void {
    if (this.data.onConfirm) {
      let createRollingContractDto = this.getCreateRollingContractDto();
      this.data.onConfirm(createRollingContractDto);
    }
    this._dialogRef.close();
  }

  private setYearOptions() {
    let currentYear = new Date().getFullYear();
    let nextYear = currentYear + 1;

    this.yearOptions = [
      {
        id: 1,
        name: `${currentYear}`,
        value: `${currentYear}`,
        isDisabled: false,
      },
      { id: 2, name: `${nextYear}`, value: `${nextYear}`, isDisabled: false },
    ];
  }

  private setFormGroup() {
    this.formGroup = new FormGroup({
      [this.formNames.opportunity]: new FormControl(""),
      [this.formNames.team]: new FormControl(""),
      [this.formNames.year]: new FormControl(
        new Date().getFullYear().toString()
      ),
      [this.formNames.contractedProject]: new FormControl(""),
      [this.formNames.contractedEmployee]: new FormControl(""),
      [this.formNames.confidence]: new FormControl(""),
    });
  }

  private subscribeToFormGroupStatusChanges() {
    this.formGroup.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        if (this.formGroup.touched && this.formGroup.status === "VALID") {
          this.disableCreateButton = false;
        } else {
          this.disableCreateButton = true;
        }
      });
  }

  private getCreateRollingContractDto(): CreateRollingContractDto {
    const formValues = this.formGroup.getRawValue();

    const employeeName = this.getDropdownOption(
      formValues,
      this.formNames.contractedEmployee
    )?.name;
    const projectName = this.getDropdownOption(
      formValues,
      this.formNames.contractedProject
    )?.name;

    return {
      opportunityName: formValues[this.formNames.opportunity],
      teamId: this.getDropdownOption(formValues, this.formNames.team)?.id,
      year: this.yearOptions.find(
        (item) => item.value === formValues[this.formNames.year]
      ).value,
      projectId: projectName
        ? this.projects.find((x) => x.name === projectName).id
        : projectName,
      employeeId: employeeName
        ? this.employees.find(
            (x) => `${x.lastName} ${x.firstName}` === employeeName
          ).id
        : employeeName,
      confidenceId: this.getDropdownOption(
        formValues,
        this.formNames.confidence
      )?.id,
    } as CreateRollingContractDto;
  }

  private getDropdownOption(
    formValues: any,
    propName: string
  ): any | undefined {
    return this.formOptions[propName].find(
      (item) => item.value.toLowerCase() === formValues[propName].toLowerCase()
    );
  }
}
