import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, mergeMap, switchMap, take } from "rxjs/operators";
import {
  ConfidenceClient,
  ContractedEmployeeClient,
  ContractedEmployeeDto,
  ContractedProjectClient,
  ContractedProjectDto,
  Employee,
  EmployeeClient,
  Project,
  ProjectClient,
  RollingContractClient,
  RollingContractDto,
  TeamClient,
} from "src/core/services/api/api-clients";
import { ContractedEmployeeOdata } from "src/core/services/api/odata/contracted-employee-odata.service";
import { ContractedProjectOdata } from "src/core/services/api/odata/contracted-project-odata.service";
import {
  createRollingContract,
  createRollingContractFailure,
  createRollingContractSuccess,
  fillRollingContractTillEndOfMonth,
  loadConfidence,
  loadConfidenceFailure,
  loadConfidenceSuccess,
  loadContractedEmployees,
  loadContractedEmployeesFailure,
  loadContractedEmployeesSuccess,
  loadContractedProjects,
  loadContractedProjectsFailure,
  loadContractedProjectsSuccess,
  loadEmployees,
  loadEmployeesFailure,
  loadEmployeesSuccess,
  loadProjects,
  loadProjectsFailure,
  loadProjectsSuccess,
  loadRollingContracts,
  loadRollingContractsFailure,
  loadRollingContractsSuccess,
  loadTeams,
  loadTeamsFailure,
  loadTeamsSuccess,
  updateContractedEmployee,
  updateContractedEmployeeFailure,
  updateContractedEmployeeSuccess,
  updateContractedProject,
  updateContractedProjectFailure,
  updateContractedProjectSuccess,
  updateRollingContract,
  updateRollingContractFailure,
  updateRollingContractSuccess,
} from "./contracts.actions";

@Injectable()
export class ContractsEffects {
  constructor(
    private _actions$: Actions,
    private _contractedEmployeeClient: ContractedEmployeeClient,
    private _contractedEmployeeOdataClient: ContractedEmployeeOdata,
    private _contractedProjectClient: ContractedProjectClient,
    private _contractedProjectOdataClient: ContractedProjectOdata,
    private _teamClient: TeamClient,
    private _rollingContractClient: RollingContractClient,
    private _confidenceClient: ConfidenceClient,
    private _employeeClient: EmployeeClient,
    private _projectClient: ProjectClient
  ) {}

  loadContractedEmployeesEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadContractedEmployees),
      take(1),
      switchMap(() => {
        return this._contractedEmployeeOdataClient
          .getAllContractedEmployees()
          .pipe(
            mergeMap((contractedEmployees) => {
              let contractedEmployeesDict: Record<
                number,
                ContractedEmployeeDto
              > = {};
              contractedEmployees.forEach((contractedEmployee) => {
                contractedEmployeesDict[contractedEmployee.id] =
                  contractedEmployee;
              });
              return [
                loadContractedEmployeesSuccess({
                  contractedEmployees: contractedEmployeesDict,
                }),
              ];
            }),
            catchError((error) => of(loadContractedEmployeesFailure({ error })))
          );
      })
    );
  });

  updateContractedEmployeeEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updateContractedEmployee),
      switchMap(({ contractedEmployee }) =>
        this._contractedEmployeeClient.update(contractedEmployee).pipe(
          mergeMap((updatedEmployee) => [
            updateContractedEmployeeSuccess({ updatedEmployee }),
          ]),
          catchError((error) => of(updateContractedEmployeeFailure({ error })))
        )
      )
    );
  });

  loadContractedProjectEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadContractedProjects),
      take(1),
      switchMap(() =>
        this._contractedProjectOdataClient.getAllActiveContractedProject().pipe(
          mergeMap((contractedProjects) => {
            let contractedEmployeesDict: Record<number, ContractedProjectDto> =
              {};
            contractedProjects.forEach((contractedProject) => {
              contractedEmployeesDict[contractedProject.id] = contractedProject;
            });
            return [
              loadContractedProjectsSuccess({
                contractedProjects: contractedEmployeesDict,
              }),
            ];
          }),
          catchError((error) => of(loadContractedProjectsFailure({ error })))
        )
      )
    );
  });

  updateContractedProjectEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updateContractedProject),
      switchMap(({ contractedProject }) => {
        return this._contractedProjectClient.update(contractedProject).pipe(
          mergeMap((updatedProject) => [
            updateContractedProjectSuccess({ updatedProject }),
          ]),
          catchError((error) => of(updateContractedProjectFailure({ error })))
        );
      })
    );
  });

  createRollingContractEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(createRollingContract),
      switchMap(({ createRollingContractDto }) => {
        return this._rollingContractClient
          .create(createRollingContractDto)
          .pipe(
            mergeMap((data) => [
              createRollingContractSuccess({ rollingContract: data }),
            ]),
            catchError((error) => of(createRollingContractFailure({ error })))
          );
      })
    );
  });

  updateRollingContractEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updateRollingContract),
      switchMap(({ rollingContract }) => {
        return this._rollingContractClient.update(rollingContract).pipe(
          mergeMap((updatedRollingContract) => [
            updateRollingContractSuccess({ updatedRollingContract }),
          ]),
          catchError((error) => of(updateRollingContractFailure({ error })))
        );
      })
    );
  });

  loadTeamsEffects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadTeams),
      take(1),
      switchMap(() => {
        return this._teamClient.getAllTeams().pipe(
          mergeMap((teams) => [loadTeamsSuccess({ teams })]),
          catchError((error) => of(loadTeamsFailure({ error })))
        );
      })
    );
  });

  loadRollingContractsEffect = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadRollingContracts),
      take(1),
      switchMap(() => {
        return this._rollingContractClient.getAll().pipe(
          mergeMap((rollingContracts) => {
            let records: Record<number, RollingContractDto> = {};

            rollingContracts.forEach(
              (contract) => (records[contract.id] = contract)
            );

            return [loadRollingContractsSuccess({ rollingContracts: records })];
          }),
          catchError((error) => of(loadRollingContractsFailure({ error })))
        );
      })
    );
  });

  loadConfidenceEffects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadConfidence),
      take(1),
      switchMap(() => {
        return this._confidenceClient.getAll().pipe(
          mergeMap((confidences) => [loadConfidenceSuccess({ confidences })]),
          catchError((error) => of(loadConfidenceFailure({ error })))
        );
      })
    );
  });

  loadEmployeesEffects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadEmployees),
      take(1),
      switchMap(() => {
        return this._employeeClient.getAll().pipe(
          mergeMap((employees) => {
            let records: Record<number, Employee> = {};

            employees.forEach((employee) => (records[employee.id] = employee));

            return [loadEmployeesSuccess({ employees: records })];
          }),
          catchError((error) => of(loadEmployeesFailure({ error })))
        );
      })
    );
  });

  loadProjectsEffects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loadProjects),
      take(1),
      switchMap(() => {
        return this._projectClient.getAll().pipe(
          mergeMap((projects) => {
            let records: Record<number, Project> = {};

            projects.forEach((project) => (records[project.id] = project));

            return [loadProjectsSuccess({ projects: records })];
          }),
          catchError((error) => of(loadProjectsFailure({ error })))
        );
      })
    );
  });

  fillRollingContractTillEndOfMonthEffect$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fillRollingContractTillEndOfMonth),
      switchMap(({ rollingContract, value, calendarId }) => {
        return this._rollingContractClient
          .fillTillEndOfMonth(rollingContract, value, calendarId)
          .pipe(
            mergeMap((updatedRollingContract) => [
              updateRollingContractSuccess({ updatedRollingContract }),
            ]),
            catchError((error) => of(updateRollingContractFailure({ error })))
          );
      })
    );
  });
}
