import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest,
} from "@azure/msal-browser";
import { Store } from "@ngrx/store";
import { EMPTY, from, Observable, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { AuthenticationSuccess } from "./store/actions";
import { IAuthState } from "./store/state";
import { environment } from "src/environments/environment";

export interface User {
  b2cId: string;
  name: string;
  email: string;
  groups: string[];
}

@Injectable()
export class AuthService implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private _broadcastService: MsalBroadcastService,
    private _msalService: MsalService,
    private _store: Store<IAuthState>
  ) {}

  getToken(): Observable<AuthenticationResult> {
    const account = this.getCurrentAccount();
    if (account == null) {
      return EMPTY;
    }

    return from(
      this._msalService.instance.acquireTokenSilent({
        account: account,
        scopes: (this._msalGuardConfig.authRequest as RedirectRequest).scopes,
      })
    );
  }

  startListening(): Observable<InteractionStatus> {
    return this._broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      tap(() => {
        const account = this.getCurrentAccount();
        if (account != null) {
          this._store.dispatch(new AuthenticationSuccess());
        }
      })
    );
  }

  isAdmin(): boolean {
    console.log(this.getToken());
    return true;
  }

  isUserAuthenticated(): boolean {
    return !!this.getCurrentAccount();
  }

  logout() {
    this._msalService.instance.logoutRedirect();
  }

  getUser(): User {
    const account = this.getCurrentAccount();
    if (account != null) {
      return {
        b2cId: account.idTokenClaims["sub"],
        name: account.name ?? this.getAccountFullName(account),
        email:
          (account.idTokenClaims["email"] as string) ??
          (account.idTokenClaims["signInName"] as string) ??
          (account.username as string),
        groups: account.idTokenClaims["groups"] as string[],
      };
    }

    return null;
  }

  getCurrentAccount(): AccountInfo {
    const accounts = this._msalService.instance.getAllAccounts();
    return accounts.length > 0 ? accounts[0] : null;
  }

  async goToLoginPage() {
    if (this._msalGuardConfig.authRequest) {
      await this._msalService.instance.loginRedirect(
        this._msalGuardConfig.authRequest as RedirectRequest
      );
    } else {
      await this._msalService.instance.loginRedirect();
    }
  }

  canUserWrite(): boolean {
    const userGroups = this.getUser().groups;

    let canWrite = !!userGroups.find((group) =>
      environment.groups.canWrite.includes(group)
    );

    return canWrite;
  }

  canUserRead() {
    const userGroups = this.getUser().groups;

    let canRead = !!userGroups.find((group) =>
      environment.groups.canRead.includes(group)
    );

    return canRead;
  }

  ngOnDestroy(): void {
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  private getAccountFullName(account: AccountInfo) {
    if (
      account.idTokenClaims["given_name"] != null &&
      account.idTokenClaims["family_name"] != null
    ) {
      return (
        account.idTokenClaims["given_name"] +
        " " +
        account.idTokenClaims["family_name"]
      );
    }

    return "";
  }
}
