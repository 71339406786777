import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AzureUserService } from 'src/core/services/azure-user.service';
import { AuthService } from '../auth.service';
import { AuthActionTypes, AuthenticationSuccess, LoadUserPhotoFailure, LoadUserPhotoSuccess, LoadUserPhoto, Logout, LoadUserDataSuccess, LoadUserData } from './actions';

@Injectable()
export class AuthEffects {
    userLogout$ = createEffect(() =>
        this._actions$.pipe(
            ofType<Logout>(AuthActionTypes.Logout),
            tap(() => this._authService.logout()),
        ),
        { dispatch: false },
    );

    loadUserData$ = createEffect(() => this._actions$.pipe(
        ofType<LoadUserData>(AuthActionTypes.LoadUserData),
        switchMap(x => this._azureUserService.getCurrentUser()),
        map(user => new LoadUserDataSuccess({ user: user }))
    ));

    loadUserPhoto$ = createEffect(() => this._actions$.pipe(
        ofType<LoadUserPhoto>(AuthActionTypes.LoadUserPhoto),
        switchMap(x => this._azureUserService.getCurrentUserPhoto()),
        map(photo => new LoadUserPhotoSuccess({ photoUrl: this._domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(photo)) })),
        catchError(() => of(new LoadUserPhotoFailure({ defaultPhotoUrl: this._domSanitizer.bypassSecurityTrustUrl('./assets/default-user.png') })))
    ));

    authenticationSuccess$ = createEffect(() => this._actions$.pipe(
        ofType<AuthenticationSuccess>(AuthActionTypes.AuthenticationSuccess),
        switchMap(() => [
            new LoadUserData(),
            new LoadUserPhoto(),
        ])
    )
    );
    constructor(private _actions$: Actions, private _domSanitizer: DomSanitizer,
        private _azureUserService: AzureUserService, private _authService: AuthService) { }
}
